import { Interweave } from "interweave";
import { styled } from "@mui/material/styles";
import { Card, CardContent, Typography, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Collapse, CardActions } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Option from "./Option";
import { memo, useState } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

// incase more options than 4, defensive handling
const choicesList = ["A", "B", "C", "D", "E", "F", "G"];

const StyledListItemButton = styled(ListItem)(({ listtitle }) => ({
  "&::before": {
    content: `"${listtitle}"`,
    position: "absolute",
    top: "-18px",
    left: "5px",
    padding: "",
    textAlign: "center",
  },
}));


const FastTrackMcq = ({ data, handleOptionSelect, cardIndex, selectedQuestion, isWrongMarkedQuestion, examSubmitted, defaultExpand }) => {
  const { question, options, correctChoiceIndex, selectedOption, detailed_solution, yearAsked, parentExam} = data;
  const [expanded, setExpanded] = useState(defaultExpand);
  
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return (
      <div className="w-[100%]  flex justify-between items-center">
      <Typography variant="h5" className={`${!expanded ? 'text-gray-700': 'text-blue-700'}  text-xl font-bold font-['Manrope']`}>
        Detailed Solution
      </Typography>
      <IconButton 
        sx={{ color: expanded ? 'blue' : 'grey' }} 
        {...other} 
      />
      </div>
    );
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginRight: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  return (
    <>
      {/* <CardContent> */}
      <div className="flex flex-col w-full items-stretch ml-0 mb-0 mt-[-50px]">
        <div className="flex flex-col">
          <div className="flex w-full justify-end">
        <Typography 
          className="h-7 w-fit px-2 py-1 bg-indigo-50 rounded-[32px] text-indigo-500 text-sm font-normal font-['Inter'] leading-tight inline-flex items-center text-end justify-end"
          gutterBottom
        >
          {parentExam} {yearAsked}
        </Typography>
        </div>
        {/* <Typography variant="h6" component="div" sx={{ textAlign: "start"}}>
          <Interweave content={question}></Interweave>
        </Typography> */}
        <h3 className="text-start  text-gray-700 text-xl font-semibold font-['Manrope'] leading-[30px]">
          <Interweave content={question}></Interweave>
        </h3>
        </div>
        <div className=" mt-4">
        <RadioGroup
          aria-label="quiz-options"
          name="quiz-options"
          value={selectedOption}
          onChange={(event) => {
            const selectedIndex = parseInt(event.target.value); 
            const eventData = {
              currentTarget: {
                dataset: {
                  cardindex: cardIndex.toString(),
                  optionindex: selectedIndex.toString(),
                  iswrongmarkedquestion: isWrongMarkedQuestion.toString()
                }
              }
            };
            handleOptionSelect(eventData);
          }}
        >
          <List>
            {options.map((option, index) => {
              let borderColor ="lightgrey";
              let outlineText = "";
              let fontsize = '1rem';
              let lineheight = '1.5rem';
              let backgroundColorAfterSubmit = "";
              if (examSubmitted) {
                if (index === correctChoiceIndex) {
                  outlineText = "Correct Answer";
                  borderColor = "#34D399";
                  fontsize = '1.25rem';
                  lineheight = '1.75rem';
                  backgroundColorAfterSubmit = "bg-green-200";
                } else if (index === selectedOption && correctChoiceIndex !== selectedOption) {
                  outlineText = "You Selected";
                  borderColor = "#f64f59";
                  fontsize = '1.25rem';
                  lineheight = '1.75rem';
                  backgroundColorAfterSubmit = "bg-red-200";
                } else if (index === correctChoiceIndex && selectedOption === -1) {
                  outlineText = "Not Attempted Correct Answer";
                  fontsize = '1.25rem';
                  lineheight = '1.75rem';
                  backgroundColorAfterSubmit = "green";
                }
              } else if (index === selectedOption) {
                backgroundColorAfterSubmit = "lightblue";
              }

              return (
                <ListItem key={index} 
                sx={{
                  ...((!examSubmitted) 
                    ? { border: '2px solid', borderColor: borderColor }
                    : { borderBottom: '2px solid', borderColor: borderColor }
                  ),
                  marginY: 1,
                  borderRadius: "10px",
                  width: "100%",
                  fontSize: fontsize,
                  lineHeight: lineheight,
                  overflow: 'visible'
                }}>
                  <FormControlLabel
                    key={option}
                    value={index}
                    control={<Radio />}
                    data-cardindex={cardIndex}
                    data-optionindex={index}
                    data-iswrongmarkedquestion={isWrongMarkedQuestion}
                    listtitle={outlineText}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: fontsize,
                        lineHeight: lineheight,
                        transition: 'font-size 0.2s, line-height 0.2s',
                        width: '100%'
                      }
                    }}
                    label={<Interweave content={option} />}
                  />
                  <span style={{ position: 'absolute', top: '18px', right: '10px', backgroundColor: 'white', paddingInline: '5px', borderRadius: '5px', fontSize: '20px', color : 'gray' }}>{outlineText}</span>
                </ListItem>
              );
            })}
          </List>
        </RadioGroup>
        </div>
      </div>
      {/* </CardContent> */}
      {examSubmitted ? (
        <>
          <Card variant="outlined" sx={{ mb: 1 }}>
            <CardActions disableSpacing  onClick={handleExpandClick}>
              <ExpandMore expand={expanded}  aria-expanded={expanded} aria-label="show more">
                <ExpandMoreIcon />
              </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent sx={{backgroundColor:"white"}}>
                <Typography paragraph>
                  <Interweave content={detailed_solution}></Interweave>
                </Typography>
              </CardContent>
            </Collapse>
          </Card>
        </>
      ) : null}
    </>
  );
};

export default FastTrackMcq;
import React, { useEffect, useRef, useState } from 'react'

const ExamTimer = ({ isPaused, initialTime, onTimeUp, onTimeUpdate }) => {
    const [timeLeft, setTimeLeft] = useState(initialTime);
    const timerRef = useRef(null);
    
    // Format time to hh:mm:ss
    const formatTime = (seconds) => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
    
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
    };

    useEffect(() => {
      if (onTimeUpdate) {
        onTimeUpdate(timeLeft);
      }
    }, [timeLeft, onTimeUpdate]);
    
    useEffect(() => {
      if (isPaused || timeLeft <= 0) {
        clearInterval(timerRef.current);
        if (timeLeft === 0) {
          onTimeUp();
        }
        return;
      }
      
      timerRef.current = setInterval(() => {
        setTimeLeft((prev) => Math.max(prev - 1, 0));
      }, 1000);
  
      return () => clearInterval(timerRef.current);
    }, [isPaused, timeLeft, onTimeUp]);
  
    return (
      <div className="font-medium">{formatTime(timeLeft)}</div>
    );
  };

export default ExamTimer
import { createTheme, DialogActions, DialogContent, Drawer, useMediaQuery } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react'
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import LeftPane from "../LeftPane/LeftPane";
import Box from "@mui/material/Box";
import OnScreenMenu from '../OnScreenMenu';
import Footer from "../Footer/Footer";
import Navbar from "../Navbar";
import Sidebar from '../Sidebar';
import FastTrackMcq from '../PYQFastTrack/FastTrackMcq';
import { useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import '../PYQFastTrack/PYQFastTrackDashboard.css'
import UserContext from "../../UserContext";
import { isLoggedIn } from "../../utils";
import BootstrapDialog from '../Modals/Dialog';
import BootstrapDialogTitle from '../Modals/BootstrapDialogTitle';

const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );
  const PrelimsTestDash = () => {
    const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
    const [openLoginPrompt, setLoginPrompt] = useState(false);
    const navigate = useNavigate();
    const handleLoginButtonPrompt = () => {
      navigate("/login", { state: { from: window.location.pathname } });
    };
    const handleLoginPromptOpen = () => {
      setLoginPrompt(true);
    };
  
    const handleLoginPromptClose = () => {
      setLoginPrompt(false);
    };
    const GoToExam = (selectedExam) =>{
      if(loggedInUserDetails.name){
        navigate('/prelims-test', { 
          state: { exam_title: selectedExam } 
          // state: { day: selectedExam } 
        });
      }else{
        handleLoginPromptOpen();
      }
    }
  return (
    <>
    <div className='mt-[40px] ml-2 w-full flex flex-col justify-start items-center gap-8 h-screen md:min-h-[750px] md:px-[100px]'>
        <Typography 
            variant="h9" 
            className="heading h9 text-center text-[#23b279] text-xl font-medium font-['Manrope'] leading-snug bg-[#e4ffe0] px-4 py-2 rounded-3xl"
          >
            Prelims Test Dashboard
          </Typography>
        <div className='px-4 md:px-[300px] w-full flex flex-wrap items-start justify-center gap-10'>
        <Box sx={{ minWidth: 275 }}>
            <Card variant="outlined">
                <CardContent>
                <div className='flex gap-4'>
                    <Typography gutterBottom sx={{ color: 'white', fontSize: 14, backgroundColor: '#25CD71', width: '70px', textAlign:'center' }}>
                        Free
                    </Typography>
                    <Typography gutterBottom sx={{ color: 'gray', fontSize: 14, backgroundColor: 'lightgray', width: '70px', textAlign:'center' }}>
                        UPSC
                    </Typography>
                </div>
                <Typography variant="h5" component="div" sx={{paddingY:3}}>
                    CB Prelims Open Test 1
                </Typography>
                {/* <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>adjective</Typography> */}
                <Typography variant="body2">
                    100 Questions {bull} 120 mins {bull} 200 Marks
                </Typography>
                </CardContent>
                <div className='flex w-full justify-between px-3 items-center'>
                <Typography variant="body2">
                    24 March '25
                </Typography>
                <CardActions>
                <Button size="small" onClick={()=>GoToExam('CB_PTS_2025_01')}>Start Exam</Button>
                {/* <Button size="small" onClick={()=>GoToExam(1)}>Start Exam</Button> */}
                </CardActions>
                </div>
            </Card>
        </Box>
        {/* <Box sx={{ minWidth: 275 }}>
            <Card variant="outlined">
                <CardContent>
                <div className='flex gap-4'>
                    <Typography gutterBottom sx={{ color: 'white', fontSize: 14, backgroundColor: '#744DEA', width: '70px', textAlign:'center' }}>
                        Zenith
                    </Typography>
                    <Typography gutterBottom sx={{ color: 'gray', fontSize: 14, backgroundColor: 'lightgray', width: '70px', textAlign:'center' }}>
                        MPPSC
                    </Typography>
                </div>
                <Typography variant="h5" component="div" sx={{paddingY:3}}>
                    Summits and Conferences
                </Typography>
             
                <Typography variant="body2">
                    100 Questions {bull} 15 mins {bull} 80 Marks
                </Typography>
                </CardContent>
                <div className='flex w-full justify-between px-3 items-center'>
                <Typography variant="body2">
                    30 March '25
                </Typography>
                <CardActions>
                <Button size="small" onClick={()=>GoToExam(2)}>Start Exam</Button>
                </CardActions>
                </div>
            </Card>
        </Box>
        <Box sx={{ minWidth: 275 }}>
            <Card variant="outlined">
                <CardContent>
                <div className='flex gap-4'>
                    <Typography gutterBottom sx={{ color: 'white', fontSize: 14, backgroundColor: '#EEDD96', width: '70px', textAlign:'center' }}>
                        PRO
                    </Typography>
                    <Typography gutterBottom sx={{ color: 'gray', fontSize: 14, backgroundColor: 'lightgray', width: '70px', textAlign:'center' }}>
                        UPPSC
                    </Typography>
                </div>
                <Typography variant="h5" component="div" sx={{paddingY:3}}>
                    Summits and Conferences
                </Typography>
                <Typography variant="body2">
                    25 Questions {bull} 15 mins {bull} 80 Marks
                </Typography>
                </CardContent>
                <div className='flex w-full justify-between px-3 items-center'>
                <Typography variant="body2">
                    30 March '25
                </Typography>
                <CardActions>
                <Button size="small" onClick={()=>GoToExam(3)}>Start Exam</Button>
                </CardActions>
                </div>
            </Card>
        </Box>
        <Box sx={{ minWidth: 275 }}>
            <Card variant="outlined">
                <CardContent>
                <div className='flex gap-4'>
                    <Typography gutterBottom sx={{ color: 'white', fontSize: 14, backgroundColor: '#EEDD96', width: '70px', textAlign:'center' }}>
                        PRO
                    </Typography>
                    <Typography gutterBottom sx={{ color: 'gray', fontSize: 14, backgroundColor: 'lightgray', width: '70px', textAlign:'center' }}>
                        UPPSC
                    </Typography>
                </div>
                <Typography variant="h5" component="div" sx={{paddingY:3}}>
                    Summits and Conferences
                </Typography>
                <Typography variant="body2">
                    25 Questions {bull} 15 mins {bull} 80 Marks
                </Typography>
                </CardContent>
                <div className='flex w-full justify-between px-3 items-center'>
                <Typography variant="body2">
                    30 March '25
                </Typography>
                <CardActions>
                <Button size="small" onClick={()=>GoToExam(4)}>Start Exam</Button>
                </CardActions>
                </div>
            </Card>
        </Box> */}
        </div>
    </div>
    <BootstrapDialog onClose={handleLoginPromptClose} aria-labelledby="login prompt" open={openLoginPrompt}>
          <BootstrapDialogTitle id="login with your google account" onClose={handleLoginPromptClose}>
            Login for this feature
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>Login to get access to this feature and many more...</Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleLoginButtonPrompt}>
              Login
            </Button>
          </DialogActions>
        </BootstrapDialog>
    </>
  )
}

const PrelimsTestDashboard = () => {
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const [isScrolled, setIsScrolled] = useState(false);
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    wrapperIsLoggedIn();
  }, []);

  const wrapperIsLoggedIn = async () => {
    let userData = await isLoggedIn();
    if (userData) {
      setLoggedInUserDetails(userData);
    }
  };
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  });
    return (
      <>
      <Helmet>
              <title>UPSC Mains Answer Evaluation in 24 Hours – AI & Expert Reviewed | Toppers Choice for UPPSC, BPSC, TSPSC, APPSC, OPSC, JPSC, KPSC</title>
              <meta
                name="description"
                content="Instant Mains Answer Evaluation for UPSC IAS Mains Exam - Providing AI-powered Answer Review for IAS aspirants. Get your UPSC IAS mains answers and full-length tests(FLTs) evaluated in just 24 hours with CollectorBabu's unique AI plus human review. Best Evaluation platform for State PCS exams - UPPSC, BPSC, APPSC, TSPSC, GPSC, HPSC, JPSC, KPSC, PPSC, MPPSC, RPSC, OPSC, CGPSC, JKPSC, APSC, TNPSC, MPSC, UKPSC, WBPSC. Faster than traditional 7-day reviews, our service combines the precision of AI with the insight of experts. Join Mains Mentorship Program with Toppers. GS | Ethics | Essay | Optional all subjects covered."
              />
              <meta
                name="keywords"
                content="UPSC IAS Mains Exam, Mains 2025, AI Evaluation, AI Answer Evaluation, Answer Review Portal, Topper's Answers, Daily Answer Writing Initiative, Daily UPSC Mains Answer Writing Practice, Answer Review, UPSC Answer Writing Review, UPSC Answer Writing Practice, IAS Topper's Answers, Model Answers, Optional Answer Writing Practice, AI Answer Review, IAS Aspirants, Answer Writing Practice, CollectorBabu, UPSC Preparation, AI for UPSC, Secure Answer Writing, TLP, Abhyaas, Convert IAS, ConvertIAS, RAS, KAS, OAS, Mains Test Series, MGP, Expert Feedback, GS Answers, FLT Evaluation, AIR 167 IAS, AIR 309 IAS, Topper Answers UPSC, Rishabh Dev, Sociology Answer Writing, Anthropology Answer Writing, PSIR Answer Writing, 24-hour Feedback, FLT Evaluation, Mains Mentorship Program"
              />
              <meta
                property="og:title"
                content="Best Mains Answer Evaluation Platform | Full Test Evaluation in 24 hours | CollectorBabu"
              />
              <meta
                property="og:url"
                content="https://collectorbabu.com"
              />
              <meta
                property="og:description"
                content="Why wait a week? Get your UPSC mains answers evaluated in 24 hours by both AI and interview-appeared human experts at CollectorBabu. Get an instant AI-driven review of your answers for UPSC IAS Mains Exam."
              />
              <meta property="og:site_name" content="Collector Babu" />
          </Helmet>
        {isMobile ? (
          <>
          <Box
      sx={{
        width: '100vw',
        maxWidth: '100%', // Add this
        overflowX: 'hidden', // Add this to prevent horizontal scrolling
        backgroundSize: 'cover',
      }}
    >
      <LeftPane Component={PrelimsTestDash} />
      <OnScreenMenu />
      <Footer />
    </Box>
          </>
        ):(  
          <>
          <Box
        sx={{
          display: 'flex',
          backgroundSize: 'cover',
          height: '100vh',
          width: '100%',
        }}
      >
  
          <Navbar  />
          <Box
          sx={{
            height: '100vh',
            backgroundSize: 'cover',
          }}
        >
          <Sidebar />
   
          <Box sx={{ display: "flex", flexDirection: "column",width:'94vw', minHeight: "100vh"}}>
            <div style={{ marginTop: '75px',marginLeft:"90px", width:"100%",paddingTop:'40px' }}> 
              <PrelimsTestDash />
              <Footer />
            </div>
          </Box>
          <OnScreenMenu/>
          </Box>
          </Box>
          </>
        )}
      </>
    );
};

export default PrelimsTestDashboard
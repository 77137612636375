import React from 'react';
import EmailIcon from '@mui/icons-material/Email';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from "react-router-dom";
import mailIcon from './material-symbols_mail.svg';
import mailIcon2 from './Mail.svg';
import ytIcon from './carbon_logo-youtube.svg';
import ytIcon2 from './line-md_youtube.svg';
import teleIcon from './mingcute_telegram-fill.svg';
import teleIcon2 from './iconoir_telegram.svg';
import phone from './phone.svg';
import vector from './Vector 1.svg';
import vector3 from './Vector 3.svg'
import './footer.css'
import { isMobile } from "react-device-detect";

const Footer = () => {
    return (
    <footer className='bg-[#f5fbf9]'>
      <div className="footercontainet">
        <div className="footercontainer-parent">
          <div className="footercontainer">
            <div className="footercontainer2">
              <img className="image-3-icon" alt="" src={"/images/mainLogo.png"}/>
              <div className="footercollectorbabu-is-your">CollectorBabu: Your Trusted Guide to Ace the UPSC Journey!</div>
                <div className="footersocials">
                <a href="https://t.me/collector_babu_original" target="_blank" rel="noopener noreferrer"><img className="iconoirtelegram" alt="" src={teleIcon2}/></a>
                  <a href="mailto:support@collectorbabu.com"><img className="iconoirtelegram" alt="" src={mailIcon2}/></a>
                  <a href="https://www.youtube.com/@collectorbabu_original" target="_blank" rel="noopener noreferrer"><img className="iconoirtelegram" alt="" src={ytIcon2}/></a>
                </div>
              </div>
            </div>
            <div className="footercontacts">
              <div className="footercontact-information">Contact Information</div>
              <div className="footere-mail">
                <img className="footerframe-icon" alt="" src={mailIcon2}/>
                <div className="footeremail-id">
                  <div className="footeremail">Email</div>
                  <a href="mailto:support@collectorbabu.com" className='text-inherit no-underline'><div className="footercollectorbabuexamplecom text-inherit no-underline">support@collectorbabu.com</div></a>
                </div>
              </div>
              <div className="footere-mail">
                <img className="footerframe-icon" alt="" src={mailIcon2}/>
                <div className="footeremail-id">
                  <div className="footeremail">Student Queries</div>
                  <a href="mailto:support@collectorbabu.com" className='text-inherit no-underline'><div className="footercollectorbabuexamplecom text-inherit no-underline">support@collectorbabu.com</div></a>
                </div>
              </div>
            </div>
            <div className="footer-services">
              <div className='services-wrapper'>
                <div className="footercontact-information ">Services</div>
              </div>
              <div className="footercontainer1">
                <div className="footerlink-parent">
                  <div className="footerlink">
                    <Link to="/upsc-mains-answer-evaluation" className='no-underline text-inherit'><div className="footerlink1">AI Answer Review</div></Link>
                  </div>
                  <div className="footerlink2">
                    <Link to="/360-reader" className='no-underline text-inherit'><div className="footerlink1">360 Reader</div></Link>
                  </div>
                  <div className="footerlink2">
                    <Link to="/flash-card" className='no-underline text-inherit'><div className="footerlink1">RattafAI</div></Link>
                  </div>
                  <div className="footerlink">
                    <Link to="/upsc-ias-mock-interview" className='no-underline text-inherit'><div className="footerlink1">Mock Interview</div></Link>
                  </div>
                </div>

                <div className="footerlink-group">
                  <div className="footerlink">
                    <Link to="/pyqexplorer" className='no-underline text-inherit'><div className="footerlink1">PYQ Explorer</div></Link>
                  </div>
                  <div className="footerlink">
                    <Link to="/pyq-fast-track" className='no-underline text-inherit'><div className="footerlink1">PYQ Fast track</div></Link>
                  </div>
                  <div className="footerlink">
                    <Link to="/upsc-mains-2024-essay-gs-questions-model-answers" className='no-underline text-inherit'><div className="footerlink1">Mains' 24</div></Link>
                  </div>
                  <div className="footerlink">
                    <Link to="/upsc-mains-2023-essay-gs-questions-model-answers" className='no-underline text-inherit'><div className="footerlink1">Mains' 23</div></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div className="footerterms">
          <div className="footercopyright-collector">COPYRIGHT © COLLECTOR BABU {new Date().getFullYear()}. All Rights Reserved</div>
          <div className="footerpolicies">
            <div className="footer-policy2">
            <div className="footerprivacy-policy"> <Link to="/terms-of-service" className='no-underline text-inherit'>Terms</Link> </div>
            <div className="footerprivacy-policy">
              <ul className="footerprivacy-policy1">
              <Link to="/privacy-policy" className='no-underline text-inherit'><li>Privacy Policy</li></Link>
              </ul>
            </div>
            </div>
            <div className="footerprivacy-policy">
              <ul className="footerprivacy-policy1">
              <Link to="/refund" className='no-underline text-inherit'><li>Refund Policy</li></Link>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>

    
    );
}

export default Footer
import { Button, createTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState, useContext, useRef, useCallback, useMemo } from 'react'
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import LeftPane from "../LeftPane/LeftPane";
import Box from "@mui/material/Box";
import OnScreenMenu from '../OnScreenMenu';
import Footer from "../Footer/Footer";
import Navbar from "../Navbar";
import Sidebar from '../Sidebar';
import FastTrackMcq from '../PYQFastTrack/FastTrackMcq';
import PrelimsMcq from './PrelimsMcq';
import { useTheme } from '@mui/material';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import { useNavigate } from 'react-router-dom';
import UserContext from "../../UserContext";
import { useLocation } from 'react-router-dom';
import TurnedInOutlinedIcon from '@mui/icons-material/TurnedInOutlined';
import '../PYQFastTrack/PYQFastTrackDashboard.css'
import axios from 'axios';
import Results from '../PYQFastTrack/Results';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import BootstrapDialog from '../Modals/Dialog';
import BootstrapDialogTitle from '../Modals/BootstrapDialogTitle';
import Slide from '@mui/material/Slide';
import ExamTimer from './ExamTimer';
import { isLoggedIn } from '../../utils';
const PrelimsTestBoard = () => {
    const theme = createTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    });
  
    return (
      <>
      <Helmet>
              <title>UPSC Mains Answer Evaluation in 24 Hours – AI & Expert Reviewed | Toppers Choice for UPPSC, BPSC, TSPSC, APPSC, OPSC, JPSC, KPSC</title>
              <meta
                name="description"
                content="Instant Mains Answer Evaluation for UPSC IAS Mains Exam - Providing AI-powered Answer Review for IAS aspirants. Get your UPSC IAS mains answers and full-length tests(FLTs) evaluated in just 24 hours with CollectorBabu's unique AI plus human review. Best Evaluation platform for State PCS exams - UPPSC, BPSC, APPSC, TSPSC, GPSC, HPSC, JPSC, KPSC, PPSC, MPPSC, RPSC, OPSC, CGPSC, JKPSC, APSC, TNPSC, MPSC, UKPSC, WBPSC. Faster than traditional 7-day reviews, our service combines the precision of AI with the insight of experts. Join Mains Mentorship Program with Toppers. GS | Ethics | Essay | Optional all subjects covered."
              />
              <meta
                name="keywords"
                content="UPSC IAS Mains Exam, Mains 2025, AI Evaluation, AI Answer Evaluation, Answer Review Portal, Topper's Answers, Daily Answer Writing Initiative, Daily UPSC Mains Answer Writing Practice, Answer Review, UPSC Answer Writing Review, UPSC Answer Writing Practice, IAS Topper's Answers, Model Answers, Optional Answer Writing Practice, AI Answer Review, IAS Aspirants, Answer Writing Practice, CollectorBabu, UPSC Preparation, AI for UPSC, Secure Answer Writing, TLP, Abhyaas, Convert IAS, ConvertIAS, RAS, KAS, OAS, Mains Test Series, MGP, Expert Feedback, GS Answers, FLT Evaluation, AIR 167 IAS, AIR 309 IAS, Topper Answers UPSC, Rishabh Dev, Sociology Answer Writing, Anthropology Answer Writing, PSIR Answer Writing, 24-hour Feedback, FLT Evaluation, Mains Mentorship Program"
              />
              <meta
                property="og:title"
                content="Best Mains Answer Evaluation Platform | Full Test Evaluation in 24 hours | CollectorBabu"
              />
              <meta
                property="og:url"
                content="https://collectorbabu.com"
              />
              <meta
                property="og:description"
                content="Why wait a week? Get your UPSC mains answers evaluated in 24 hours by both AI and interview-appeared human experts at CollectorBabu. Get an instant AI-driven review of your answers for UPSC IAS Mains Exam."
              />
              <meta property="og:site_name" content="Collector Babu" />
          </Helmet>
        {isMobile ? (
          <>
          <Box
      sx={{
        width: '100vw',
        maxWidth: '100%', // Add this
        overflowX: 'hidden', // Add this to prevent horizontal scrolling
        backgroundSize: 'cover',
      }}
    >
      <PrelimsTest />
      {/* <LeftPane Component={PrelimsTest} /> */}
      {/* <OnScreenMenu /> */}
      {/* <Footer /> */}
    </Box>
          </>
        ):(  
          <>
          <Box
        sx={{
          display: 'flex',
          backgroundSize: 'cover',
          height: '100vh',
          width: '100%',
        }}
      >
  
          {/* <Navbar  /> */}
          <Box
          sx={{
            height: '100vh',
            backgroundSize: 'cover',
          }}
        >
          {/* <Sidebar /> */}
  
          <Box sx={{ display: "flex", flexDirection: "column",width:'100vw', minHeight: "100vh"}}>
            <div style={{ width:"100%" }}> 
              <PrelimsTest />
          {/* <Footer /> */}
            </div>
          </Box>
          {/* <OnScreenMenu/> */}
          </Box>
          </Box>
          </>
        )}
      </>
    );
};


const PrelimsTest = () => {
    const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
    const [language, setLanguage] = useState("English");
    const [selectedExam, setSelectedExam] = useState("UPSC IAS");
    const [startExam, setStartExam] = useState(false);
    const [renderIndex, setRenderIndex] = useState(0);
    const [examSubmitted, setExamSubmitted] = useState(false);
    // const [timeLeft, setTimeLeft] = useState(7200);
    const [isPaused, setIsPaused] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [questionsLoading, setQuestionsLoading] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [savedQuestions, setSavedQuestions] = useState([]);
    const [submissionStats, setSubmissionStats] = useState({
      correctAnswers: null,
      wrongAnswers: null,
      unAttemptedAnswers: null,
      repeatedCorrectAnswers: null,
      repeatedWrongAnswers: null,
    });
    const [openLoginPrompt, setLoginPrompt] = useState(false);
    const [openSubmitPrompt, setOpenSubmitPrompt] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [attemptedAnswers, setAttemptedAnswers] = useState(0);
    const [submissionComplete, setSubmissionComplete] = useState(false);
    const [savedTime, setSavedTime] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    // const day = location.state?.day;
    const exam_title = location.state?.exam_title;
    const handleLoginButtonPrompt = useCallback(() => {
      navigate("/login", { state: { from: "/prelims-test-dashboard" } });
    },[]);
    const handleLoginPromptOpen = useCallback(() => {
      setLoginPrompt(true);
    },[]);
  
    const handleLoginPromptClose = useCallback(() => {
      setLoginPrompt(false);
    },[]);

    const [loading, setLoading] = useState(true);
    
      const wrapperIsLoggedIn = async () => {
        let userData = await isLoggedIn();
        if (userData) {
          setLoggedInUserDetails(userData);
        } else {
          setOpenLoginPrompt(true);
        }
        setLoading(false);
      };
      
      useEffect(() => {
        wrapperIsLoggedIn();
      }, []);
      
      useEffect(() => {
        if (!loading && !loggedInUserDetails?.user_id) {
          navigate("/login");
        }
      }, [loggedInUserDetails, loading, navigate]);

    const toggleDrawer = useCallback((newOpen) => () => {
      setOpen(newOpen);
    },[]);
    const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    });  
    
    const currentTimeRef = useRef(savedTime || 7200);

    // Add this callback function to get the current time from the ExamTimer
    const handleTimerUpdate = (time) => {
      currentTimeRef.current = time;
      // console.log(currentTimeRef);
    };
        
    const saveExamProgress = useCallback(() => {
      if (!loggedInUserDetails.user_id || !questions.length) return;
      
      const progressData = {
        renderIndex,
        questions,
        savedQuestions,
        attemptedAnswers,
        startExam,
        examSubmitted,
        submissionStats,
        examTitle: exam_title,
        timestamp: Date.now(),
        timeRemaining: currentTimeRef.current
      };
      
      localStorage.setItem(`exam_progress_${loggedInUserDetails.user_id}_${exam_title}`, JSON.stringify(progressData));
    }, [renderIndex, questions, savedQuestions, attemptedAnswers, startExam, examSubmitted, submissionStats, exam_title, loggedInUserDetails.user_id]);

    const loadExamProgress = useCallback(() => {
      if (!loggedInUserDetails.user_id) return false;
      
      const savedProgress = localStorage.getItem(`exam_progress_${loggedInUserDetails.user_id}_${exam_title}`);
      if (!savedProgress) return false;
      
      try {
        const progressData = JSON.parse(savedProgress);
        
        const isExpired = (Date.now() - progressData.timestamp) > ( 60 * 60 * 1000);
        if (progressData.examTitle !== exam_title || isExpired) {
          localStorage.removeItem(`exam_progress_${loggedInUserDetails.user_id}_${exam_title}`);
          return false;
        }
        
        // Restore all state
        setRenderIndex(progressData.renderIndex);
        setQuestions(progressData.questions);
        setSavedQuestions(progressData.savedQuestions);
        setAttemptedAnswers(progressData.attemptedAnswers);
        setStartExam(progressData.startExam);
        setExamSubmitted(progressData.examSubmitted);

        if (typeof progressData.timeRemaining === 'number') {
          setSavedTime(progressData.timeRemaining);
          currentTimeRef.current = progressData.timeRemaining;
        }
        
        if (progressData.examSubmitted) {
          setSubmissionComplete(true);
          setSubmissionStats(progressData.submissionStats);
        }
        
        return true;
      } catch (error) {
        console.error("Error loading saved progress:", error);
        return false;
      }
    }, [exam_title, loggedInUserDetails.user_id]);


    const handleSubmitExam = useCallback(async () => {
      if (!loggedInUserDetails.name) {
        handleLoginPromptOpen();
        return;
      }
      
      if (submitLoader || submissionComplete) {
        return;
      }
      
      setOpenSubmitPrompt(false);
      setSubmitLoader(true);
      
      try {
        let correctAnswers = 0;
        let wrongAnswers = 0;
        let unAttemptedAnswers = 0;
        let repeatedCorrectAnswers = 0;
        let repeatedWrongAnswers = 0;
        let userAttemptedQuestionsData = [];
        
        for (let i = 0; i < questions.length; i++) {
          const question = questions[i];
          const selectedOption = 'selectedOption' in question ? Number(question.selectedOption) : -1;
          
          if ('selectedOption' in question && selectedOption !== -1) {
            userAttemptedQuestionsData.push(question);
          }

          if (!('selectedOption' in question) || selectedOption === -1) {
            unAttemptedAnswers++;
          } else if (Number(question.correctChoiceIndex) === selectedOption) {
            correctAnswers++;
          } else {
            wrongAnswers++;
          }
        }
        
        setSubmissionStats({
          correctAnswers,
          wrongAnswers,
          unAttemptedAnswers,
          repeatedCorrectAnswers,
          repeatedWrongAnswers,
        });

        //  for (let questionData of userAttemptedQuestionsData) {
        //     let selectedOption = Number(questionData.selectedOption);
        //     let isCorrect = selectedOption === Number(questionData.correctChoiceIndex);
        //     let body = {
        //       user_id: loggedInUserDetails.user_id,
        //       question_id: questionData._id,
        //       exam_name: exam_title,
        //       user_response_index: selectedOption,
        //       is_correct: isCorrect,
        //     };
              
        //     await axios.post("https://collectorbabu.com/api/user/user_question_response", body, {
        //       headers: {
        //         accept: "application/json",
        //         "Content-Type": "application/json",
        //         token: "87957bdf-8fb3-4afc-a5c7-4ad62f785a60",
        //       },
        //     });
        //   }
        
        setTimeout(() => {
          setSubmitLoader(false);
          setExamSubmitted(true);
          setSubmissionComplete(true);

          setTimeout(() => saveExamProgress(), 100);
        }, 100);
        
      } catch (error) {
        console.error("Submission error:", error);
        setSubmitLoader(false);
      }
    }, [loggedInUserDetails, questions, handleLoginPromptOpen, submitLoader, submissionComplete]);


    const handleStartExam = useCallback(async () => {
      if (!loggedInUserDetails.name) {
        console.log("User not logged in");
        return;
      }

      const progressLoaded = loadExamProgress();
      if (progressLoaded) {
        console.log("Restored saved exam progress");
        return;
      }
      
      try {
        setQuestionsLoading(true);
        const body = {
          user_id: loggedInUserDetails.user_id,
          parentExam: language === "Hindi" ? selectedExam + " Hindi" : selectedExam,
          // day : day
          exam_title : exam_title
        };
        
        const response = await axios.post(
          "https://collectorbabu.com/api/pyq/scheduled", 
          body, 
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
              token: "87957bdf-8fb3-4afc-a5c7-4ad62f785a60",
            },
          }
        );
        
      const processedQuestions = response.data.questions.map(q => ({
        ...q,
        selectedOption: -1
      }));
      
      setQuestions(processedQuestions);
      setQuestionsLoading(false);
    } catch (error) {
      console.error("Error fetching questions:", error);
      setQuestionsLoading(false);
    }

      // }, [loggedInUserDetails, language, selectedExam, day]);
    }, [loggedInUserDetails, language, selectedExam, exam_title]);
    
    // console.log(questions);
    const modifySelectedOptionFromState = (questionArr, cardIndex, selectedOption) => {
      let tempQuestionsState = new Array(questionArr.length);
  
      for (let index = 0; index < questionArr.length; index++) {
        tempQuestionsState[index] = questionArr[index];
  
        if (index === Number(cardIndex)) {
          tempQuestionsState[index]["selectedOption"] = Number(selectedOption);
        }
      }
  
      return tempQuestionsState;
    };
      
    const handleOptionSelect = async (event) => {
      // console.log(Number(event.currentTarget.dataset.cardindex),Number(event.currentTarget.dataset.optionindex),event.currentTarget.dataset.iswrongmarkedquestion === "true" ? true : false)
      if (examSubmitted) {
        return;
      }
      
      if (loggedInUserDetails.name) {
        let cardIndex = Number(event.currentTarget.dataset.cardindex);
        let selectedOption = Number(event.currentTarget.dataset.optionindex);
        // let isWrongMarkedQuestion = event.currentTarget.dataset.iswrongmarkedquestion === "true" ? true : false;
        
        let tempQuestionsState;
        
        const currentQuestion = questions[cardIndex];
        const isDeselection = selectedOption === -1;
        const wasAlreadySelected = currentQuestion.selectedOption !== -1 && currentQuestion.selectedOption !== undefined;
        
        tempQuestionsState = modifySelectedOptionFromState(questions, cardIndex, selectedOption);
        setQuestions(tempQuestionsState);
        if (isDeselection) {
          setAttemptedAnswers(prev => prev - 1);
        } else if (!wasAlreadySelected) {
          setAttemptedAnswers(prev => prev + 1);
        }

        if (!isDeselection) {
          try {
            const questionData = questions[cardIndex];
            const isCorrect = selectedOption === Number(questionData.correctChoiceIndex);
            
            const body = {
              user_id: loggedInUserDetails.user_id,
              question_id: questionData._id,
              exam_name: exam_title,
              user_response_index: selectedOption,
              is_correct: isCorrect,
            };
            
            await axios.post("https://collectorbabu.com/api/user/user_question_response", body, {
              headers: {
                accept: "application/json",
                "Content-Type": "application/json",
                token: "87957bdf-8fb3-4afc-a5c7-4ad62f785a60",
              },
            });
          } catch (error) {
            console.error("Error sending user response:", error);
          }
        }

        setTimeout(() => saveExamProgress(), 100);
      } else {
        handleLoginPromptOpen();
      }
    };
        // const handleOptionSelect = useCallback((event) => {
        //   if (examSubmitted || !loggedInUserDetails.name) {
        //     if (!loggedInUserDetails.name) handleLoginPromptOpen();
        //     return;
        //   }
          
        //   const cardIndex = Number(event.currentTarget.dataset.cardindex);
        //   const selectedOption = Number(event.currentTarget.dataset.optionindex);
          
        //   // Use functional state update to avoid stale state issues
        //   setQuestions(prevQuestions => {
        //     // Only update the specific question that changed
        //     return prevQuestions.map((q, idx) => 
        //       idx === cardIndex 
        //         ? { ...q, selectedOption } 
        //         : q
        //     );
        //   });
          
        //   // Update attempted answers count if it's a new selection
        //   setAttemptedAnswers(prev => {
        //     const currentQuestion = questions[cardIndex];
        //     const isNewAttempt = !('selectedOption' in currentQuestion) || currentQuestion.selectedOption === -1;
        //     return isNewAttempt ? prev + 1 : prev;
        //   });
        // }, [examSubmitted, loggedInUserDetails, questions, handleLoginPromptOpen]);

      // useEffect(()=>{
      //   handleStartExam();
      // },[])
      // const toggleSavedQuestion = useCallback((index) => {
      //   setSavedQuestions(prevSaved => {
      //     if (prevSaved.includes(index)) {
      //       return prevSaved.filter(i => i !== index);
      //     } else {
      //       return [...prevSaved, index];
      //     }
      //   });
      // }, []);

      const toggleSavedQuestion = useCallback((index) => {
        setSavedQuestions(prevSaved => {
          const newSaved = prevSaved.includes(index)
            ? prevSaved.filter(i => i !== index)
            : [...prevSaved, index];
          
          // Save progress after updating
          setTimeout(() => saveExamProgress(), 100);
          return newSaved;
        });
      }, [saveExamProgress]);
      const handleCloseSubmitPrompt = useCallback(() => {
        setIsPaused(false);
        setOpenSubmitPrompt(false);
      }, []);
      useEffect(() => {
        if (startExam && !examSubmitted && questions.length > 0) {
          // Save progress whenever key state changes
          saveExamProgress();
          
          // Also set up periodic saving (every 30 seconds)
          const intervalId = setInterval(saveExamProgress, 30000);
          return () => clearInterval(intervalId);
        }
      }, [startExam, examSubmitted, questions, renderIndex, savedQuestions, attemptedAnswers, saveExamProgress]);
      useEffect(() => {
        handleStartExam();

        const handleBeforeUnload = () => {
          if (startExam && !examSubmitted) {
            saveExamProgress();
          }
        };
        
        window.addEventListener('beforeunload', handleBeforeUnload);
        
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, [loggedInUserDetails, exam_title]);

      const [resultsPage, setResultsPage] = useState(0);
      const questionsPerPage = 10;
      
      const paginatedQuestions = useMemo(() => {
        if (!examSubmitted) return [];
        const startIdx = resultsPage * questionsPerPage;
        return questions.slice(startIdx, startIdx + questionsPerPage);
      }, [examSubmitted, questions, resultsPage]);
      
      const totalPages = useMemo(() => 
        Math.ceil(questions.length / questionsPerPage),
        [questions.length]
      );

      const SubmitDialog = () => (
        <Dialog
          open={openSubmitPrompt && !examSubmitted}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseSubmitPrompt}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Confirm Submission"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              You have attempted {attemptedAnswers} out of {questions.length} <br/>
              Are you sure you want to submit?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSubmitPrompt}>Disagree</Button>
            <Button onClick={handleSubmitExam}>Agree</Button>
          </DialogActions>
        </Dialog>
      );

      const LoginDialog = () => (
        <BootstrapDialog 
          onClose={handleLoginPromptClose} 
          aria-labelledby="login prompt" 
          open={openLoginPrompt}
        >
          <BootstrapDialogTitle id="login with your google account" onClose={handleLoginPromptClose}>
            Login for this feature
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>Login to get access to this feature and many more...</Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleLoginButtonPrompt}>
              Login
            </Button>
          </DialogActions>
        </BootstrapDialog>
      );
      const wrapperRef = useRef(null);

      const scrollToTop = () => {
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 180);
      };

      return (
      <div className='w-[100vw] h-[100vh] overflow-x-hidden overflow-y-scroll'>
      <div className="exam-container w-[100vw] overflow-x-hidden h-[100vh] overflow-y-auto">
        <div className='w-[100%] shadow-md flex justify-start px-8'>
            <img
              src="/images/mainLogo.png"
              alt="collectorbabu-logo"
              className="collectorbabu-logo"
            />
        </div>
        <div className='md:px-12 px-4'>
        {!startExam ? (
          <>
          <div className='flex flex-col gap-2 px-2 pt-5 items-center text-start md:px-4'>
            <Typography 
              variant="h9" 
              className="heading h9 w-fit text-center text-[#23b279] text-xl font-medium font-['Manrope'] leading-snug bg-[#e4ffe0] px-4 py-2 rounded-3xl"
            >
              Prelims Test Dashboard
            </Typography>
            <h1 className='w-full text-start'>**General Instructions:**</h1>
            <ol className='flex flex-col gap-1.5 w-full text-start'>
              <li>1. The clock will be set at the server. The countdown timer at the top right corner of screen will display the remaining time available for you to complete the examination. When the timer reaches zero, the examination will end by itself. You need not terminate the examination or submit your paper.</li>  
              <li>2. The Question Palette displayed on the right side of screen will show the status of each question using one of the following symbols:
                <ul >
                  {/* <li className='flex gap-2 pl-4'> <div className='w-5 h-5 rounded-full bg-[#2d80f6] border-2 border-slate-400'/> - You are currently on  that question.</li> */}
                  <li className='flex gap-2 pl-4'> <div className='w-5 h-5 rounded-full bg-white border-2 border-slate-400'/> - You have not answered the question.</li>
                  <li className='flex gap-2 pl-4'> <div className='w-5 h-5 rounded-full bg-blue-200 border-2 border-slate-400'/>- You have answered the question.</li>
                  <li className='flex gap-2 pl-4'> <div className='w-5 h-5 rounded-full bg-yellow-200 border-2 border-slate-400'/>- You have NOT answered the question, but have marked the question for review.</li>
                  {/* <li className='flex gap-2 pl-4'> <div className='w-5 h-5 rounded-full bg-yellow-500 border-2 border-slate-400'/>- You have answered the question, but marked it for review.</li> */}
                </ul>
              </li>
            </ol>
            <p className='w-full text-start'>The Mark For Review status for a question simply indicates that you would like to look at that question again. If a question is answered, but marked for review, then the answer will be considered for evaluation unless the status is modified by the candidate.</p>

            <h1 className='w-full text-start'>**Navigating to a Question:**</h1>
            <ol className='flex flex-col gap-1.5 w-full text-start'>
              <li>3. To answer a question, do the following:
                <ol>
                  <li>- Click on the question number in the Question Palette at the right of your screen to go to that question directly.</li>
              
                  <li>- Select one of the option to save your answer for the current question and then go to the next question.</li>
              
                  <li>- Click on <TurnedInNotIcon fontSize="large" className="w-full h-full" /> icon to Mark the current question for review, then go to the next question.</li>
                </ol>
              </li>
            </ol>
            {/* <p className='w-full text-start'>Note that your answer for the current question will not be saved, if you navigate to another question directly by clicking on a question number without saving the answer to the previous question.</p> */}

            <p className='w-full text-start'>You can view all the questions by clicking on Menu icon above Question number. This feature is provided, so that if you want you can just see the entire question paper at a glance.</p>

            <h1 className='w-full text-start'>**Answering a Question:**</h1>
              <ol className='flex flex-col gap-1.5 text-start w-full'>
              <li >4. Procedure for answering a multiple choice (MCQ) type question:
                <ul>
                <li> - Choose one answer from the 4 options (A, B, C, D) given below the question, click on the bubble placed before the chosen option.</li>
                
                {/* <li> - To deselect your chosen answer, click on the bubble of the chosen option again or click on the Clear Response button.</li> */}
                
                <li> - To change your chosen answer, click on the bubble of another option.</li>
                
                {/* <li> - To save your answer, you MUST click on the Save & Next.</li> */}
                </ul>
              </li>
              {/* <li>5. Procedure for answering a numerical answer type question:

                1. To enter a number as your answer, use the virtual numerical keypad.

                2. A fraction (e.g. -0.3 or -.3) can be entered as an answer with or without "0" before the decimal point. As many as four decimal points, e.g. 12.5435 or 0.003 or -932.6711 or 12.82 can be entered.
                
                3. To clear your answer, click on the Clear Response button.

                4. To save your answer, you MUST click on the Save & Next.
              </li> */}
              <li>5. To mark a question for review, click on the Mark for Review & Next button. If an answer is selected for a question that is Marked for Review, that answer will be considered in the evaluation.</li>

              <li>6. To change your answer to a question that has already been answered, first select that question for answering and then follow the procedure for answering that type of question.</li>

              <li>7. Note that ONLY Questions for which answers are saved or marked for review after answering will be considered for evaluation.</li>

              {/* <li>8. Sections in this question paper are displayed on the top bar of the screen. Questions in a Section can be viewed by clicking on the name of that Section. The Section you are currently viewing will be highlighted.</li> */}

              {/* <li>9. After clicking the Save & Next button for the last question in a Section, you will automatically be taken to the first question of the next Section in sequence.</li> */}

              {/* <li>11. You can move the mouse cursor over the name of a Section to view the answering status for that Section.</li> */}
            </ol>
          </div>
          <div className='flex justify-between items-center my-4 px-4'>
          <button 
            onClick={() => navigate('/prelims-test-Dashboard')} 
            className={`w-fit cursor-pointer px-10 py-2 rounded border border-[#23b279] bg-green-50 flex-col justify-center items-start gap-2 inline-flex`}
          >
            <div className="justify-start items-center gap-1 inline-flex">
              <div className={`text-justify text-[#23b279] text-xl font-normal font-['Manrope'] leading-none`}>
                Back
              </div>
            </div>
          </button>
          <button 
            onClick={() => {
              if(loggedInUserDetails.name){
                setStartExam(true);
                setIsPaused(false); 
              }else{
                handleLoginPromptOpen()
              }
            }} 
            className={`w-fit cursor-pointer px-10 py-2 rounded border border-[#23b279] bg-green-50 flex-col justify-center items-start gap-2 inline-flex`}
          >
            <div className="justify-start items-center gap-1 inline-flex">
              <div className={`text-justify text-[#23b279] text-xl font-normal font-['Manrope'] leading-none`}>
                Next
              </div>
            </div>
          </button>
          </div>
          </>
        ):(
          !examSubmitted ? (
        <div className="h-[100%] w-full md:px-4 pt-3 flex flex-col justify-around ">
        <div className="flex-1 ">
          {/* Container for drawer and main content */}
          <div className="h-full">
            {/* Timer and pause */}
            <div className="flex items-center justify-between gap-4 px-4 py-2 border-b-2">
                <IconButton 
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
                <ExamTimer
                  isPaused={!startExam || isPaused} 
                  initialTime={savedTime || 7200} 
                  onTimeUp={handleSubmitExam} 
                  onTimeUpdate={handleTimerUpdate}
                />
                {/* <button 
                  onClick={togglePause} 
                  className="px-2 py-1 border-2 hover:bg-gray-100 active:bg-gray-200 transition-colors"
                >
                  {isPaused ? 'Resume' : 'Pause'}
                </button> */}
                <Button 
                    variant="outlined" 
                    onClick={() => {
                      setOpenSubmitPrompt(true);
                      setIsPaused(true);
                    }}
                    disabled={submitLoader}
                  >
                    {submitLoader ? 'Submitting...' : 'SUBMIT'}
                </Button>
            </div>

            {/* Drawer */}
            <Drawer
                anchor="left"
                open={open}
                onClose={toggleDrawer(false)}
                variant="temporary"
                ModalProps={{
                  container: document.getElementById('drawer-container'),
                  style: { position: 'absolute' }
                }}
                PaperProps={{
                  style: { position: 'absolute' }
                }}
                sx={{
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: {
                      xs: 350,    // Mobile/small screens
                      sm: 400,    // Tablets
                      md: 500,    // Desktop/larger screens
                      lg: 600     // Large desktop screens
                    },
                    height: 'calc(100% - 48px)', 
                    top: '48px',
                  },
                }}
              >
                <Box sx={{ width: '100%', maxHeight: '100%', overflowY: 'auto' }} role="presentation">
                  <List 
                    sx={{ 
                      py: 0,
                      '& .MuiListItem-root': { 
                        py: 1, 
                        px: 2 
                      } 
                    }}
                  >
                    {questions.map((question, index) => (
                      <ListItem key={index} disablePadding>
                        <ListItemButton 
                          selected={renderIndex === index}
                          onClick={() => {
                            setRenderIndex(index);
                            toggleDrawer(false)();
                          }}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            backgroundColor: renderIndex === index ? '#e0e0e0' : 'transparent',
                            '&:hover': {
                              backgroundColor: renderIndex === index ? '#e0e0e0' : '#f5f5f5'
                            },
                            border: savedQuestions.includes(index) ? '2px solid #FDFD96' : 
                            ('selectedOption' in question && question.selectedOption !== -1)? '2px solid lightBlue' : 
                            '2px solid lightGrey',
                            borderRadius: 2
                          }}
                        >
                          <ListItemText 
                            primary={
                              <Typography 
                                variant="body2" 
                                sx={{ 
                                  fontWeight: 'bold', 
                                  font: 'inherit',
                                  color: renderIndex === index ? 'primary.main' : 'text.primary',
                                  display: '-webkit-box',
                                  overflow: 'hidden',
                                  WebkitBoxOrient: 'vertical',
                                  WebkitLineClamp: 3,
                                  lineHeight: 1.4,
                                  height: '3.9em',
                                }}
                              >
                                {/* Remove HTML tags and truncate */}
                                {question.question.replace(/<[^>]*>/g, '')}
                              </Typography>
                            }
                            // secondary={
                            //   question.selectedOption !== -1 ? 
                            //     <Typography variant="caption" color="green">Answered</Typography> : 
                            //     <Typography variant="caption" color="error">Unanswered</Typography>
                            // }
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Drawer>
            <div className='flex w-full items-start justify-between h-[85vh]'>
            {/* Main content */}
            <div id="drawer-container" className="relative w-full h-full overflow-y-auto pt-3">
              <div className="min-w-full max-h-[93%] min-h-[70%] overflow-y-auto relative  md:min-w-[80%] md:px-4 flex flex-col gap-3 md:gap-20">
                <div className='w-full h-[10%] flex items-center justify-between'>
                  <Typography 
                    sx={{ fontSize: 20, textAlign: "start", marginTop: '10px', fontWeight: '600' }}
                    className="text-black text-2xl font-semibold font-['Manrope']" 
                    gutterBottom
                  >
                    Question : {renderIndex + 1} / {questions.length}
                  </Typography>
                  <div className='flex w-fit gap-2 '>
                  <Button
                    className={savedQuestions.includes(renderIndex) ? 'bg-green-200 w-10 h-10' : 'bg-slate-200 w-10 h-10'}
                    onClick={() => toggleSavedQuestion(renderIndex)}
                  >
                    {savedQuestions.includes(renderIndex) ? (
                      <TurnedInOutlinedIcon fontSize="large" className="w-full h-full" />
                    ) : (
                      <TurnedInNotIcon fontSize="large" className="w-full h-full" />
                    )}
                  </Button>
                  </div>
                </div>
                <div className='pt-9 min-h-[85%] '>
                {questions.length > 0 && renderIndex < questions.length && (
                  <PrelimsMcq
                    data={questions[renderIndex]}
                    selectedQuestion={null}
                    key={questions[renderIndex].question}
                    cardIndex={renderIndex}
                    handleOptionSelect={handleOptionSelect}
                    isWrongMarkedQuestion={false}
                    examSubmitted={examSubmitted}
                    defaultExpand={false}
                  />
                )}
                </div>
              </div>
              {/* Navigation buttons */}
              <div className="w-full z-10 absolute bottom-0 right-0 h-[60px] px-5 py-3 flex justify-between items-center gap-6">
                <button 
                  disabled={renderIndex === 0} 
                  onClick={() => setRenderIndex(renderIndex - 1)} 
                  className={`w-[150px] cursor-pointer  py-2 rounded border ${renderIndex === 0 ? 'border-gray-100 bg-white' : 'border-[#23b279] bg-green-50'} justify-center items-start gap-2 inline-flex`}
                >
                  <div className="justify-start items-center gap-1 inline-flex">
                    <div className={`text-justify ${renderIndex === 0 ? 'text-[#5f6774]' : 'text-[#23b279]'} text-xl font-normal font-['Manrope'] leading-none`}>
                      Previous
                    </div>
                  </div>
                </button>
                <button 
                  disabled={renderIndex === questions.length - 1} 
                  onClick={() => setRenderIndex(renderIndex + 1)} 
                  className={`w-[150px] cursor-pointer py-2 rounded border ${renderIndex === questions.length -1 ? 'border-gray-100 bg-white' : 'border-[#23b279] bg-green-50'} justify-center items-start gap-2 inline-flex`}
                >
                  <div className="justify-start items-center gap-1 inline-flex">
                    <div className={`text-justify ${renderIndex === questions.length -1 ? 'text-[#5f6774]' : 'text-[#23b279]'} text-xl font-normal font-['Manrope'] leading-none`}>
                      Next
                    </div>
                  </div>
                </button>
              </div>
            </div>
              <div className="hidden xl:grid w-full overflow-y-auto h-[735px] grid-cols-5 md:w-[500px] gap-2 p-2 rounded ">
                {questions.map((question, index) => (
                  <button
                    key={index}
                    className={`
                      border-1 
                      text-center 
                      shadow-md 
                      p-3 
                      rounded-full 
                      m-2
                      ${index === renderIndex ? 'bg-[#2d80f6] border border-[#2d80f6]' :
                        savedQuestions.includes(index)
                          ? 'bg-yellow-200 border border-[#eaf3ff]'
                          : ('selectedOption' in question && question.selectedOption !== -1)
                            ? 'bg-blue-200 border border-[#eaf3ff]'
                            : 'border border-[#eaf3ff]'
                      }
                    `}
                    onClick={() => {
                      // setNewOrRepeated("new");
                      setRenderIndex(index);
                    } }
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>

      </div>
      ):(
        <div ref={wrapperRef} className='md:px-4 mt-6 overflow-x-hidden w-full flex flex-col gap-5 relative'>
          <button 
            onClick={() => navigate('/prelims-test-Dashboard')} 
            className={`absolute top-0 right-0 md:top-10 md:right-10 w-fit cursor-pointer px-10 py-2 rounded border border-[#23b279] bg-green-50 flex-col justify-center items-start gap-2 inline-flex`}
          >
            <div className="justify-start items-center gap-1 inline-flex">
              <div className={`text-justify text-[#23b279] text-xl font-normal font-['Manrope'] leading-none`}>
                Back
              </div>
            </div>
          </button>
          <div className='mt-9'>
          <Results submissionStats={submissionStats} />
          </div>
          <div className='flex flex-col gap-2 py-5'>
          {/* {questions.map((question, index) => (
            <div className='py-3'>
            <PrelimsMcq
              key={index}
              data={question}
              handleOptionSelect={handleOptionSelect}
              cardIndex={index}
              selectedQuestion={null}
              isWrongMarkedQuestion={false}
              examSubmitted={examSubmitted}
              defaultExpand={false}
            />
            </div>
          ))} */}
          {paginatedQuestions.map((question, index) => (
            
                    <div className='py-3 flex flex-col gap-10' key={`result-${resultsPage * questionsPerPage + index}`}>
                      <Typography 
                        sx={{ fontSize: 20, textAlign: "start", marginTop: '10px', fontWeight: '600' }}
                        className="text-black text-2xl font-semibold font-['Manrope']" 
                        gutterBottom
                      >
                        Question : {resultsPage * questionsPerPage + index + 1} / {questions.length}
                      </Typography>
                      <PrelimsMcq
                        data={question}
                        handleOptionSelect={handleOptionSelect}
                        cardIndex={resultsPage * questionsPerPage + index}
                        selectedQuestion={null}
                        isWrongMarkedQuestion={false}
                        examSubmitted={examSubmitted}
                        defaultExpand={false}
                      />
                    </div>
                  ))}
                {totalPages > 1 && (
                    <div className="flex justify-center mt-4 gap-4">
                      <button 
                        onClick={() => {
                          setResultsPage(prev => Math.max(0, prev - 1));
                          scrollToTop();
                        }}
                        disabled={resultsPage === 0}
                        className="w-[150px] flex justify-center py-2 text-justify text-[#23b279] text-xl font-normal font-['Manrope'] leading-none border-[#23b279] bg-green-50 rounded disabled:opacity-50"
                      >
                        Previous
                      </button>
                      <span className="min-w-fit flex items-center">
                        Page {resultsPage + 1}/{totalPages}
                      </span>
                      <button 
                        onClick={() => {
                          setResultsPage(prev => Math.min(totalPages - 1, prev + 1))
                          scrollToTop();
                        }}
                        disabled={resultsPage >= totalPages - 1}
                        className="w-[150px] flex justify-center py-2 text-[#23b279] text-xl font-normal font-['Manrope'] leading-none border-[#23b279] bg-green-50 rounded disabled:opacity-50"
                      >
                        Next
                      </button>
                    </div>
                  )} 
          </div>
        </div>
      ) )}
        </div>
      </div >
      
      <SubmitDialog />
      <LoginDialog />
    </div>
  );
}

export default PrelimsTestBoard
import React, { useContext, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import "./index.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import GetAppIcon from "@mui/icons-material/GetApp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import PdfDataArrayContext from "../../PDFContext";
import CircularProgress from "@mui/material/CircularProgress";
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
// import TextField from "@mui/material/TextField";
import CachedIcon from '@mui/icons-material/Cached';
// import { classNames } from 'primereact/utils';
// import { FilterMatchMode, FilterOperator } from 'primereact/api';
// import { DataTable } from 'primereact/datatable';
// import { Column } from 'primereact/column';
// import { InputText } from 'primereact/inputtext';
// import { IconField } from 'primereact/iconfield';
// import { InputIcon } from 'primereact/inputicon';
// import { Dropdown } from 'primereact/dropdown';
// import { MultiSelect } from 'primereact/multiselect';
// import { Tag } from 'primereact/tag';
// import { Calendar } from 'primereact/calendar';
// import { Card } from "primereact/card";
import FilterListIcon from '@mui/icons-material/FilterList';
import { Dialog, DialogTitle, DialogContent, DialogActions, FormGroup, FormControlLabel, Checkbox, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import 'primereact/resources/themes/saga-blue/theme.css';
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {filterData} from '../../data/filterData.js';
import dayjs from 'dayjs';

const List = ({ loggedInUserDetails, updateWidgetData }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loadingActions, setLoadingActions] = useState({});
  const [pdfData, setPdfData] = useContext(PdfDataArrayContext);
  const loggedInUserDetails_user_id=loggedInUserDetails.user_id
  const [status, setStatus] = useState([]);
  const [subject, setSubject] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null); 

 
  const subjectFilter = filterData.subjectFilter;
  const statusFilter =  filterData.statusFilter;
  // const [subjectFilter, setSubjectFilter] = useState(['GS', 'GS4', 'ESSAY', 'SOCIOLOGY', 'ANTHROPOLOGY','TELANGANAMOVEMENT','LAW']);
  // const [statusFilter, setStatusFilter] = useState(['Error', 'Completed', 'Uploaded', 'Uploading', 'Review']);
  const [openBackdrop, setOpenBackDrop] = useState(false);
  const [filterApplied, setFilterApplied] = useState([]);
  const rowsPerPage = 10;
  const maxPageNumbersToShow = 10;
  const handleCloseBackDrop = () => {
    setOpenBackDrop(false);
  };
  const handleOpenBackDrop = () => {
    setOpenBackDrop(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  const handleView = async (id,pdf_name) => {
    try {
      setLoadingActions(prevLoadingActions => ({ ...prevLoadingActions, [id]: true }));
      window.open(`/view/${id}/${loggedInUserDetails_user_id}/${pdf_name}`, "_blank");
    } catch (error) {
      console.error('Error viewing PDF:', error);
    } finally {
      setLoadingActions(prevLoadingActions => ({ ...prevLoadingActions, [id]: false }));
    }
  };


  const handleEdit = (mains_answer_sheet_hash, pdf_name) => {
    window.open(`/edit/${mains_answer_sheet_hash}/${loggedInUserDetails_user_id}/${pdf_name}`, "_blank");
  };  

  const getStatusStyle = (status) => {
    switch (status) {
      case "Error":
        return {
          color: "red",
          backgroundColor: "rgba(255, 0, 0, 0.1)",
        };
      case "Uploaded":
        return {
          color: "blue", 
          backgroundColor: "rgba(0, 0, 255, 0.1)",
        };
      case "Completed":
        return {
          color: "green",
          backgroundColor: "rgba(0, 128, 0, 0.151)",
        };
        case "Uploading":
        return {
          color: "orange",
          backgroundColor: "rgba(255, 165, 0, 0.1)",
        };
        case "Review":
        return {
          color: "yellow",
          backgroundColor: "rgba(128, 128, 0, 0.5)",
        };
      default: 
        return {
          color: "red",
          backgroundColor: "rgba(255, 0, 0, 0.1)",
        };
    }
  };

  useEffect(() => {
    fetchData(); 
  }, [loggedInUserDetails, page, subject, status, statusFilter, startDate]); 

  const fetchData = async () => {
    if (!loggedInUserDetails_user_id) return;
    try {
      setLoading(true);
      const response = await axios.post(
        "https://collectorbabu.com/api/mains/mains_get_answer_sheet_status",
        { user_id: loggedInUserDetails_user_id, subject, status, page, start_date: startDate, end_date: endDate },
      );
      setPdfData({
        answer_sheet_status: response.data.answer_sheet_status || [],
        stats: response.data.stats || [],
      });
      const totalPdfs = response.data.stats.total || 0;
      setTotalPages(Math.ceil(totalPdfs / rowsPerPage));
  
      const newWidgetData = {
        uploaded: { count: response.data.stats.total || 0 },
        queue: { count: response.data.stats.queue || 0 },
        completed: { count: response.data.stats.completed || 0 }
      };
      updateWidgetData(newWidgetData);
  
    } catch (error) {
      console.error("Error fetching PDF list:", error);
    } finally {
      setLoading(false);
    }
  };

  const downloadPDF = async (id, pdf_name) => {
    try {
        setLoadingActions(prevLoadingActions => ({ ...prevLoadingActions, [id]: true }));
        const response = await axios.post(
            "https://collectorbabu.com/api/mains/mains_answer_sheet_download",
            { user_id: loggedInUserDetails_user_id, mains_answer_sheet_hash: id },
        );
       const pdfLink=response.data.pdf_url
       const pdfResponse=await axios.get(
        pdfLink ,{ responseType: "blob" }
       )
       const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = fileURL;
          link.setAttribute('download', pdf_name || 'collectorBabu.pdf'); 
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(fileURL);
          document.body.removeChild(link); 

    } catch (error) {
        console.error("Error downloading PDF:", error);
    } finally {
        setLoadingActions(prevLoadingActions => ({ ...prevLoadingActions, [id]: false }));
        setOpen(true);
    }
};
  const handleStatusChange = async (id, currentStatus) => {
    if (currentStatus === "Review" || currentStatus === "Completed") {
      try {
        setLoadingActions((prevLoadingActions) => ({
          ...prevLoadingActions,
          [id]: true,
        }));
        await axios.post(
          "https://collectorbabu.com/api/mains/mains_answer_sheet_status_change",
          { user_id : loggedInUserDetails_user_id, mains_answer_sheet_hash: id  }
        );
        fetchData();
      } catch (error) {
        console.error("Error changing PDF status:", error);
      } finally {
        setLoadingActions((prevLoadingActions) => ({
          ...prevLoadingActions,
          [id]: false,
        }));
      }
    } else {
      console.error("Cannot change status for this PDF.");
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const calculatePageRange = () => {
    const half = Math.floor(maxPageNumbersToShow / 2);
    let start = Math.max(0, page - half);
    let end = Math.min(totalPages, start + maxPageNumbersToShow);

    if (end - start < maxPageNumbersToShow) {
      start = Math.max(0, end - maxPageNumbersToShow);
    }

    return { start, end };
  };

  const { start, end } = calculatePageRange();

  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleRowSelection = (rowId) => {
    setSelectedRowId(rowId);
  };


  const getRowStyle = (rowId) => {
    return rowId === selectedRowId 
      ? { backgroundColor: '#e4ffe0' }
      : {};
  };


  return (
    <>
    <div className="flex flex-col">
      <div className="listTitle">
        <h1 className='table-text-latest'>Latest Uploads</h1>
        <Button
          onClick={fetchData}
          sx={{
            backgroundColor: '#2196f3',
            color: 'white', 
            '&:hover': {
              backgroundColor: '#1976d2', 
            },
            marginLeft: '10px',
          }}
        >
          Refresh
        </Button>
        <Button variant="outlined" style={{gap:1}} onClick={handleOpenBackDrop} startIcon={<FilterListIcon/>}>
          Filter
          {filterApplied.length !== 0 && <span className="py-1 px-2 bg-blue-200 ml-1 rounded-full" >{filterApplied.length}</span>}
        </Button>
        <FilterComponent 
        open={openBackdrop}
        onClose={handleCloseBackDrop} 
        subjectFilter={subjectFilter} 
        statusFilter={statusFilter} 
        setSubject={setSubject}
        setStatus={setStatus}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        startDate={startDate}
        endDate={endDate}
        setFilterApplied={setFilterApplied}
        />
     </div>
      <div>
        {filterApplied.length > 0 && (
            <>
            <div className="flex gap-1 my-1 ">
              {filterApplied.map((item, index) => (
                <div key={index} className="flex border-2 w-auto rounded-lg">
                  <span className="bg-blue-200 p-1">{item.type}</span>
                  <span className="p-1">{item.value}</span>
                </div>
              ))}
              </div>
            </>
          )}
      </div>
    </div> 
    <TableContainer component={Paper} className="table" style={{ overflow: 'auto', height: '78vh' }}>
      <Table sx={{ minWidth: 850, height:"" }} aria-label="simple table" >
        <TableHead>
          <TableRow className="tableRow">
            <TableCell className="tableCell">Tracking ID</TableCell>
            {pdfData.answer_sheet_status && pdfData.answer_sheet_status[0] && pdfData.answer_sheet_status[0].user_name && (
              <TableCell style={{ textAlign: 'center' }}>User Name</TableCell>)}
            <TableCell className="tableCell">PDF Name</TableCell>
            <TableCell className="tableCell">Subject</TableCell>
            <TableCell className="tableCell">Date of Submission</TableCell>
            <TableCell className="tableCell">PDF Status</TableCell>
            <TableCell className="tableCell">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {pdfData.length === 0 && (
      <div className="empty-list-message">
      <img  
      className="empty-list-image"
      src="https://i.pinimg.com/736x/ae/8a/c2/ae8ac2fa217d23aadcc913989fcc34a2.jpg" 
      alt="empty-list"/>
        <p className="empty-list-text">Upload PDF to get started</p>
      </div>
    )}
  {loading ? (
    <TableRow>
      <TableCell colSpan={5} align="center">
        <CircularProgress />
      </TableCell>
    </TableRow>
  ) : (
    pdfData?.answer_sheet_status?.length > 0 ?
  (
    pdfData.answer_sheet_status.filter(row => row.pdf_name && row.pdf_name.toLowerCase().includes(searchTerm.toLowerCase())).map((row) => (
      <TableRow key={row.mains_answer_sheet_hash} className="tableRowValues" style={getRowStyle(row.mains_answer_sheet_hash)}  onClick={() => handleRowSelection(row.mains_answer_sheet_hash)}>
        <TableCell className="tableCell">{row.mains_answer_sheet_hash.slice(-6)}</TableCell>
        {row.user_name && <TableCell className="tableCell">{row.user_name}</TableCell>}
        <TableCell className="tableCell" title={row.pdf_name}>
        {row.raw_pdf_url ? (
          <a 
            href={row.raw_pdf_url} 
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {row.pdf_name.slice(0, 10)}
          </a>
        ) : (
          row.pdf_name.slice(0, 10)
        )}
      </TableCell>

        <TableCell className="tableCell pdfSubject" title={row.subject}>{row.subject.slice(0,7)}</TableCell>
        <TableCell className="tableCell">{row.created_at.substring(0, 16).replace('T', ' ')}</TableCell>
        <TableCell className="tableCell">
          <span className="status" style={getStatusStyle(row.status)}>
            {row.status}
          </span>
        </TableCell>
       
         <TableCell className="tableCell">
  {loadingActions[row.mains_answer_sheet_hash] ? (
    <CircularProgress size={20} />
  ) : (
    <>
      {row.public_url ? (
        row.status === 'Review' ? (
          <div className="actions-icons-r">
            <CachedIcon
              onClick={() => handleStatusChange(row.mains_answer_sheet_hash, row.status)}
              className="action-icon"
            />
            <EditIcon
              onClick={() => handleEdit(row.mains_answer_sheet_hash, row.pdf_name)}
              className="action-icon"
            />
          </div>
        ) : row.status === 'Completed' ? (
          <div className="actions">
            <EditIcon
              onClick={() => handleEdit(row.mains_answer_sheet_hash, row.pdf_name)}
              className="action-icon"
            />
            <VisibilityIcon
              onClick={() => handleView(row.mains_answer_sheet_hash, row.pdf_name)}
              className="action-icon"
            />
            <GetAppIcon
              onClick={() => downloadPDF(row.mains_answer_sheet_hash, row.pdf_name)}
              className="action-icon"
            />
            <CachedIcon
              onClick={() => handleStatusChange(row.mains_answer_sheet_hash, row.status)}
              className="action-icon"
            />
          </div>
        ) : (
          <div style={{ textAlign: "center" }}><p>-</p></div>
        )
      ) : (
        <div style={{ textAlign: "center" }}><p>-</p></div>
      )}
    </>
  )}
</TableCell>


      </TableRow>
    ))
  ) : (
    <TableRow>
      <TableCell colSpan={5} align="center">
        No data available
      </TableCell>
    </TableRow>
  )
  )}
  <div className="pagination-container-cb"></div>
  <div style={{ height: 53 }}>
        <div className="pagination-container-cb" colSpan={5}>
          <button
            className="previous-next-button"
            onClick={() => handlePageChange(Math.max(page - 1, 0))}
            disabled={page === 0}
          >
            Previous
          </button>
          {[...Array(end - start)].map((_, index) => (
            <button
              key={index}
              className={`page-button ${page === start + index ? 'active' : ''}`}
              onClick={() => handlePageChange(start + index)}
            >
              {start + index + 1}
            </button>
          ))}
          <button
            className="previous-next-button"
            onClick={() => handlePageChange(Math.min(page + 1, totalPages - 1))}
            disabled={page >= totalPages - 1}
          >
            Next
          </button>
        </div>
      </div>
</TableBody>

      </Table>
    </TableContainer>
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="success" variant="filled" sx={{ width: '100%' }}>
        Your pdf has been downloaded
      </Alert>
    </Snackbar>
    </>
  );
};

const FilterComponent = ({ open, onClose, subjectFilter, statusFilter, setSubject, setStatus, startDate, endDate, setStartDate, setEndDate, setFilterApplied}) => {
  
  const [selectedSubject, setSelectedSubject] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [onScreen, setOnScreen] = useState('date');
  const [dateFilterOption, setDateFilterOption] = useState('lastSevenDays');
  const [customStartDate, setCustomStartDate] = useState(startDate); 
  const [customEndDate, setCustomEndDate] = useState(endDate);

  // const [statuses] = useState(['Error', 'Completed', 'Uploaded', 'Uploading', 'Review']);
  // const [subjects] = useState(['GS', 'GS4', 'ESSAY', 'SOCIOLOGY', 'ANTHROPOLOGY','TELANGANAMOVEMENT','LAW']);

  const handleTransactionStatusChange = (event) => {
    const value = event.target.name;
    setSelectedSubject((prev) =>
      prev.includes(value) ? prev.filter((status) => status !== value) : [...prev, value]
    );
  };

  const handlePaymentModeChange = (event) => {
    const value = event.target.name;
    setSelectedStatus((prev) =>
      prev.includes(value) ? prev.filter((mode) => mode !== value) : [...prev, value]
    );
  };

  const handleClearAll = () => {
    setSelectedSubject([]);
    setSelectedStatus([]);
    setDateFilterOption('');
    setStartDate(null);
    setEndDate(null);
    setCustomEndDate(null);
    setCustomStartDate(null);
    setFilterApplied([]);
  };

  const handleApply = () => {
    const appliedFilters = [];
  
    if (selectedSubject.length > 0) {
      appliedFilters.push(...selectedSubject.map(sub => ({ type: 'Subject', value: sub })));
      setSubject(selectedSubject);
    }
  
    if (selectedStatus.length > 0) {
      appliedFilters.push(...selectedStatus.map(stat => ({ type: 'Status', value: stat })));
      setStatus(selectedStatus);
    }
  
    if (dateFilterOption === 'customDate' && customStartDate && customEndDate) {
      appliedFilters.push({ type: 'Date', value: `From ${customStartDate} to ${customEndDate}` });
      setStartDate(customStartDate);
      setEndDate(customEndDate);
    } else if (customStartDate && customEndDate) {
      appliedFilters.push({ type: 'Date', value: `From ${customStartDate} to ${customEndDate}` });
      setStartDate(customStartDate);
      setEndDate(customEndDate);
    }
  
    setFilterApplied(appliedFilters);
    
    onClose(); 
  };
  

  const handleDateFilterChange = (event) => {
    const option = event.target.value;
    setDateFilterOption(option);
  
    switch (option) {
      case 'today':
        setCustomStartDate(dayjs().startOf('day').format('YYYY-MM-DD'));
        setCustomEndDate(dayjs().endOf('day').format('YYYY-MM-DD'));
        break;
      case 'yesterday':
        setCustomStartDate(dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'));
        setCustomEndDate(dayjs().subtract(1, 'day').endOf('day').format('YYYY-MM-DD'));
        break;
      case 'lastSevenDays':
        setCustomStartDate(dayjs().subtract(6, 'day').format('YYYY-MM-DD'));
        setCustomEndDate(dayjs().format('YYYY-MM-DD'));
        break;
      case 'oneMonth':
        setCustomStartDate(dayjs().subtract(1, 'month').format('YYYY-MM-DD'));
        setCustomEndDate(dayjs().format('YYYY-MM-DD'));
        break;
      case 'lastSixMonths':
        setCustomStartDate(dayjs().subtract(6, 'month').format('YYYY-MM-DD'));
        setCustomEndDate(dayjs().format('YYYY-MM-DD'));
        break;
      case 'customDate':
        break;
      default:
        break;
    }
  };
  

  return (
    <Dialog open={open} onClose={onClose} className="min-h-3/4" maxWidth="md" fullWidth>
      <DialogTitle className="border-b-2">
        Filters
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
      <div className="flex w-full">
        <div className="flex flex-col w-[24%] mt-4 gap-3 px-3">
        <Button
              size="large"
              onClick={() => setOnScreen("date")}
              style={{
                backgroundColor: onScreen === "date" ? "lightblue" : "white",
                color: onScreen === "date" ? "blue" : "black",
              }}
            >
              Date
            </Button>
        <Button
          size="large"
          onClick={() => setOnScreen("subject")}
          style={{
            backgroundColor: onScreen === "subject"? "lightblue" : "white",
            color: onScreen === "subject"? "blue" : "black",
          }}
        >
          Subject
        </Button>

        <Button
          size="large"
          onClick={() => setOnScreen("status")}
          style={{
            backgroundColor: onScreen === "status"? "lightblue" : "white",
            color: onScreen === "status"? "blue" : "black",
          }}
        >
          Status
        </Button>
        </div>
        <div className="flex-1 border-l-2 p-4" style={{ minHeight: '400px' }}>
            {onScreen === 'subject' && (
              <>
             <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedSubject.length === subjectFilter.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedSubject(subjectFilter);
                      } else {
                        setSelectedSubject([]);
                      }
                    }}
                    name="selectAll"
                  />
                }
                label="Select All"
                style={{
                  color: selectedSubject.length === subjectFilter.length ? 'blue' : 'black',
                  borderRadius: '8px',
                  padding: '10px',
                }}
              />
              <FormGroup style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
                {subjectFilter.map((sub) => (
                  <FormControlLabel
                    key={sub.value}
                    control={
                      <Checkbox
                        checked={selectedSubject.includes(sub.value)}
                        onChange={handleTransactionStatusChange}
                        name={sub.value}
                      />
                    }
                    label={sub.text}
                    style={{
                      border: selectedSubject.includes(sub)? '2px solid blue' : '2px solid lightgrey',
                      color: selectedSubject.includes(sub) ? 'blue' : 'black',
                      borderRadius: '8px',
                      padding: '10px',
                    }}
                  />
                ))}
              </FormGroup>
              </>
            )}
            {onScreen === 'status' && (
              <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedStatus.length === statusFilter.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedStatus(statusFilter);
                      } else {
                        setSelectedStatus([]);
                      }
                    }}
                    name="selectAll"
                  />
                }
                label="Select All"
                style={{
                  color: selectedStatus.length === statusFilter.length ? 'blue' : 'black',
                  borderRadius: '8px',
                  padding: '10px',
                }}
              />
              <FormGroup style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
                {statusFilter.map((stat) => (
                  <FormControlLabel
                    key={stat}
                    control={
                      <Checkbox
                        checked={selectedStatus.includes(stat)}
                        onChange={handlePaymentModeChange}
                        name={stat}
                      />
                    }
                    label={stat}
                    style={{
                      border: selectedStatus.includes(stat)? '2px solid blue' : '2px solid lightgrey',
                      color: selectedStatus.includes(stat) ? 'blue' : 'black',
                      borderRadius: '8px',
                      padding: '10px',
                    }}
                  />
                ))}
              </FormGroup>
              </>
            )}
            {onScreen === 'date' && (
              <>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>Date Filter</InputLabel>
                  <Select
                    value={dateFilterOption}
                    onChange={handleDateFilterChange}
                    label="Date Filter"
                    sx={{
                      backgroundColor: '#f5f5f5',
                      borderRadius: '8px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'lightgray',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'blue',
                      },
                    }}
                  >
                    <MenuItem value="today">Today</MenuItem>
                    <MenuItem value="yesterday">Yesterday</MenuItem>
                    <MenuItem value="lastSevenDays">Last Seven Days</MenuItem>
                    <MenuItem value="oneMonth">1 Month</MenuItem>
                    <MenuItem value="lastSixMonths">Last 6 Months</MenuItem>
                    <MenuItem value="customDate">Custom Date</MenuItem>
                  </Select>
                </FormControl>

                {dateFilterOption === 'customDate' && (
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
                    <TextField
                      label="Start Date"
                      type="date"
                      value={customStartDate}
                      onChange={(e) => setCustomStartDate(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        flex: 1,
                        '& .MuiInputBase-input': {
                          padding: '10px',
                          backgroundColor: '#f5f5f5',
                          borderRadius: '8px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'lightgray',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'blue',
                        },
                      }}
                    />
                    <TextField
                      label="End Date"
                      type="date"
                      value={customEndDate}
                      onChange={(e) => setCustomEndDate(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        flex: 1,
                        '& .MuiInputBase-input': {
                          padding: '10px',
                          backgroundColor: '#f5f5f5',
                          borderRadius: '8px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'lightgray',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'blue',
                        },
                      }}
                    />
                  </Box>
                )}
              </>
            )}
          </div>
      </div>
      </DialogContent>
      <DialogActions className="border-t-2">
        <Button onClick={handleClearAll}>Clear All Filters</Button>
        <Button onClick={handleApply} variant="contained" color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default List;

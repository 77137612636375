export const filterData = {
    subjectFilter: [
        { value: 'GS', text: 'GS' },
        { value: 'GS4', text: 'GS4' },
        { value: 'ESSAY', text: 'ESSAY' },
        { value: 'SOCIOLOGY', text: 'SOCIOLOGY' },
        { value: 'ANTHROPOLOGY', text: 'ANTHROPOLOGY' },
        { value: 'TELANGANAMOVEMENT', text: 'TELANGANAMOVEMENT' },
        { value: 'LAW', text: 'LAW' },
        { value: 'PSIR', text: 'PSIR' },
        { value: 'HISTORY', text: 'HISTORY' },
        { value: 'PSYCHOLOGY', text: 'PSYCHOLOGY' },
        { value: 'PUBAD', text: 'PUBAD' },
        { value: 'PHILOSOPHY', text: 'PHILOSOPHY' },
        { value: 'GEOGRAPHY', text: 'GEOGRAPHY' },
        { value: 'UPGS', text: 'GS-UP' }
    ],
    statusFilter: ['Error', 'Completed', 'Uploaded', 'Uploading', 'Review'],
};

import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import UserContext from "../../UserContext";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import InitialsImage from './InitialsImage'; 
import LoginPrompt from "../LoginPrompt";
import { useMediaQuery, createTheme, Tooltip, DialogContentText } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LeftPane from "../LeftPane/LeftPane";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import OnScreenMenu from '../OnScreenMenu';
import Footer from "../Footer/Footer";
import Box from "@mui/material/Box";
import { IoRefresh } from "react-icons/io5";
import MainsAnswer from "./MainsAnswer";
import axios from "axios";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { CiImageOn } from "react-icons/ci";
import { FaRegFilePdf } from "react-icons/fa";
import { FilePlus } from "@phosphor-icons/react";
import { GoLock } from "react-icons/go";
import { RxCross2 } from "react-icons/rx";
import { XCircle } from "@phosphor-icons/react";
import {CircleLoader} from "react-spinners"
import { isLoggedIn, mainsAnswerReview } from "../../utils";
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";
import "./index.css";
import { Helmet } from "react-helmet";
import { useGoogleLogin } from "@react-oauth/google";
import Cookies from "js-cookie";
import PhonePop from "../PhoneNumberPopup/PhonePop"; 
import ImageMasonry from "../ImageMasonry/ImageMasonry";
import SamplePdf from './SamplePdf.pdf';
import { Document, Page, pdfjs } from 'react-pdf';
import { motion } from "framer-motion";
import aiImage from './ai.png';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const AnswerEvaluationContainer =(props)=>{
  const { loggedInUser, responseFromGoogleLogin } = props;
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const theme = createTheme();
  const gaEventTracker = useAnalyticsEventTracker("upsc-mains-answer-evaluation");
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  useEffect(() => {
    wrapperIsLoggedIn();
    gaEventTracker(`${loggedInUserDetails.name ? loggedInUserDetails.name : "logged-out user"}/dashboard`);
  }, []);

  
  const wrapperIsLoggedIn = async () => {
    let userData = await isLoggedIn();
    if (userData) {
      setLoggedInUserDetails(userData);
    } else {
    //   setOpenLoginPrompt(true);
    }
  }; 

  // useEffect(() => {
  //   ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  // });

  return (
    <>
      {isMobile ? (
        <>
        <Helmet>
            <title>UPSC Mains Answer Evaluation in 24 Hours – AI & Expert Reviewed | Toppers Choice for UPPSC, BPSC, TSPSC, APPSC, OPSC, JPSC, KPSC</title>
            <meta
              name="description"
              content="Instant Mains Answer Evaluation for UPSC IAS Mains Exam - Providing AI-powered Answer Review for IAS aspirants. Get your UPSC IAS mains answers and full-length tests(FLTs) evaluated in just 24 hours with CollectorBabu's unique AI plus human review. Best Evaluation platform for State PCS exams - UPPSC, BPSC, APPSC, TSPSC, GPSC, HPSC, JPSC, KPSC, PPSC, MPPSC, RPSC, OPSC, CGPSC, JKPSC, APSC, TNPSC, MPSC, UKPSC, WBPSC. Faster than traditional 7-day reviews, our service combines the precision of AI with the insight of experts. Join Mains Mentorship Program with Toppers. GS | Ethics | Essay | Optional all subjects covered."
            />
            <meta
              name="keywords"
              content="UPSC IAS Mains Exam, Mains 2025, AI Evaluation, AI Answer Evaluation, Answer Review Portal, Topper's Answers, Daily Answer Writing Initiative, Daily UPSC Mains Answer Writing Practice, Answer Review, UPSC Answer Writing Review, UPSC Answer Writing Practice, IAS Topper's Answers, Model Answers, Optional Answer Writing Practice, AI Answer Review, IAS Aspirants, Answer Writing Practice, CollectorBabu, UPSC Preparation, AI for UPSC, Secure Answer Writing, TLP, Abhyaas, Convert IAS, ConvertIAS, RAS, KAS, OAS, Mains Test Series, MGP, Expert Feedback, GS Answers, FLT Evaluation, AIR 167 IAS, AIR 309 IAS, Topper Answers UPSC, Rishabh Dev, Sociology Answer Writing, Anthropology Answer Writing, PSIR Answer Writing, 24-hour Feedback, FLT Evaluation, Mains Mentorship Program"
            />
            <meta
              property="og:title"
              content="Best Mains Answer Evaluation Platform | Full Test Evaluation in 24 hours | CollectorBabu"
            />
            <meta
              property="og:url"
              content="https://collectorbabu.com/upsc-mains-answer-evaluation"
            />
            <meta
              property="og:description"
              content="Why wait a week? Get your UPSC mains answers evaluated in 24 hours by both AI and interview-appeared human experts at CollectorBabu. Get an instant AI-driven review of your answers for UPSC IAS Mains Exam."
            />
            <meta property="og:site_name" content="Collector Babu" />
        </Helmet>
        <LeftPane Component={AnswerEvaluation} loggedInUser={loggedInUserDetails}  responseFromGoogleLogin={responseFromGoogleLogin}/>
        <OnScreenMenu/>
    <Footer/>
        </>
      ):(  
        <>
        <Helmet>
            <title>UPSC Mains Answer Evaluation in 24 Hours – AI & Expert Reviewed | Toppers Choice for UPPSC, BPSC, TSPSC, APPSC, OPSC, JPSC, KPSC</title>
            <meta
              name="description"
              content="Instant Mains Answer Evaluation for UPSC IAS Mains Exam - Providing AI-powered Answer Review for IAS aspirants. Get your UPSC IAS mains answers and full-length tests(FLTs) evaluated in just 24 hours with CollectorBabu's unique AI plus human review. Best Evaluation platform for State PCS exams - UPPSC, BPSC, APPSC, TSPSC, GPSC, HPSC, JPSC, KPSC, PPSC, MPPSC, RPSC, OPSC, CGPSC, JKPSC, APSC, TNPSC, MPSC, UKPSC, WBPSC. Faster than traditional 7-day reviews, our service combines the precision of AI with the insight of experts. Join Mains Mentorship Program with Toppers. GS | Ethics | Essay | Optional all subjects covered."
            />
            <meta
              name="keywords"
              content="UPSC IAS Mains Exam, Mains 2025, AI Evaluation, AI Answer Evaluation, Answer Review Portal, Topper's Answers, Daily Answer Writing Initiative, Daily UPSC Mains Answer Writing Practice, Answer Review, UPSC Answer Writing Review, UPSC Answer Writing Practice, IAS Topper's Answers, Model Answers, Optional Answer Writing Practice, AI Answer Review, IAS Aspirants, Answer Writing Practice, CollectorBabu, UPSC Preparation, AI for UPSC, Secure Answer Writing, TLP, Abhyaas, Convert IAS, ConvertIAS, RAS, KAS, OAS, Mains Test Series, MGP, Expert Feedback, GS Answers, FLT Evaluation, AIR 167 IAS, AIR 309 IAS, Topper Answers UPSC, Rishabh Dev, Sociology Answer Writing, Anthropology Answer Writing, PSIR Answer Writing, 24-hour Feedback, FLT Evaluation, Mains Mentorship Program"
            />
            <meta
              property="og:title"
              content="Best Mains Answer Evaluation Platform | Full Test Evaluation in 24 hours | CollectorBabu"
            />
            <meta
              property="og:url"
              content="https://collectorbabu.com/upsc-mains-answer-evaluation"
            />
            <meta
              property="og:description"
              content="Why wait a week? Get your UPSC mains answers evaluated in 24 hours by both AI and interview-appeared human experts at CollectorBabu. Get an instant AI-driven review of your answers for UPSC IAS Mains Exam."
            />
            <meta property="og:site_name" content="Collector Babu" />
        </Helmet>
        <Box
      sx={{
        display: 'flex',
        backgroundSize: 'cover',
        height: '100vh',
        width: '100%',
      }}
    >

        <Navbar  />
        <Box
        sx={{
          height: '100vh',
          backgroundSize: 'cover',
        }}
      >
        <Sidebar />

        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh", marginTop:'60px' }}>
          <div style={{ marginTop: '75px',marginLeft:"90px", width:"93.5vw" }}> 
            <AnswerEvaluation loggedInUser={loggedInUserDetails}  responseFromGoogleLogin={responseFromGoogleLogin}/>
          </div>
        <Footer />
        </Box>
        <OnScreenMenu/>
        </Box>
        </Box>
        </>
      )}
    </>
  );
}

const AnswerEvaluation = (props) => {
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const { loggedInUser, responseFromGoogleLogin } = props;
  const [images, setImages] = useState([]);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [timer, setTimer] = useState(59);
  const [openLoginPrompt, setOpenLoginPrompt] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [questionText, setQuestionText] = useState("");
  const [isApiWorking, setIsApiWorking] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [errorMessageB2c, setErrorMessageB2c] = useState("");
  const [questionError, setQuestionError] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const trackEvent = useAnalyticsEventTracker("AiAnswerReview Submit");
  const [showImageUploadContent, setShowImageUploadContent] = useState(
    !loggedInUserDetails.user_id
  );
  const [showPdfUploadContent, setShowPdfUploadContent] = useState(
    !showImageUploadContent
  );
  const [modelAnswer, setModelAnswer] = useState("");
  const [pdfInfoMessage, setPdfInfoMessage] = useState(false);
  const [pdfInfoMessageB2c, setPdfInfoMessageB2c] = useState("");
  const [selectedOption, setSelectedOption] = useState("GS");
  const [manualAnswer, setManualAnswer] = useState("");
  const [showManualQuestionUpload, setshowManualQuestionUpload] =
    useState(true);
  const [alreadyAttempted, setAlreadyAttempted] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [question, setQuestion] = useState("");
  const [characterCount, setCharacterCount] = useState(0);
  const [selectedSection, setSelectedSection] = useState(loggedInUserDetails.premium || Object.keys(loggedInUserDetails).length === 0 ? 'image':'pdf');
  const [showImageOption, setShowImageOption] = useState(loggedInUserDetails.premium ? true : false);
  const isMobile = React.useRef(window.innerWidth <= 768);
  const [selectedPdfUpload, setSelectedPdfUpload] = useState(false);
  const [selectedImageUpload, setSelectedImageUpload] = useState(true);
  const [selectedSubject, setSelectedSubject] = useState("GS");
  const [showOptionalSubjects, setShowOptionalSubjects] = useState(false);
  const mobAvail = loggedInUserDetails.mobile_available;
  // console.log("loggedInUserDetails", loggedInUserDetails);
  // console.log("mobAvail", mobAvail);
  const subjects = [
    {id: 1,value: "GS",label: "GS 1/2/3"},
    {id: 2,value: "GS4",label: "GS4"},
    {id: 3,value: "ESSAY",label: "Essay"},
    {id: 4,value: "Optional",label: "Optional"},
  ]
  useEffect(() => {
    const handleResize = () => {
      isMobile.current = window.innerWidth <= 768;
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const subjectFilter = [
    {id : 5,value: "SOCIOLOGY",label: "Sociology"},
    {id : 6,value: "ANTHROPOLOGY",label: "Anthropology"},
    {id : 7,value: "LAW",label: "Law"},
    {id : 8,value: "PSIR",label: "PSIR"},
    {id : 9,value: "HISTORY",label: "History"},
    {id : 10,value: "PSYCHOLOGY",label: "Psychology"},
    {id : 11,value: "PUBAD",label: "PUBAD"},
    {id : 12,value: "PHILOSOPHY",label: "Philosophy"},
    {id : 13,value: "TELANGANAMOVEMENT",label: "Telangana Movement"},
    {id : 14,value: "GEOGRAPHY",label: "Geography"},
    {id : 15,value:"UPGS", label:"GS-UP"}
  ]

  
  const [openInfo, setOpenInfo] = React.useState(false);

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const wrapperIsLoggedIn = async () => {
    let userData = await isLoggedIn();
    if (userData) {
      setLoggedInUserDetails(userData);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handlePdfFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    console.log(selectedFiles);
    if (selectedFiles.length) {
      setPdfFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    }
    event.target.value = "";
  };

  useEffect(() => {
    wrapperIsLoggedIn();
  }, []);

  const handleBackClick = () => {
    setShowOptionalSubjects(false);
    setSelectedSubject(null);
  };

  const navigate = useNavigate();

  const handleLoginPromptOpen = () => {
    setOpenLoginPrompt(true);
  };

  const handleLoginPromptClose = () => {
    setOpenLoginPrompt(false);
  };

  const handlePdfChange = (event) => {
    const files = Array.from(event.target.files);
    const validPdfs = files.filter((file) => file.type === "application/pdf");

    if (validPdfs.length === 0) {
      setSnackbarMessage("Only PDF files are allowed.");
      setSnackbarSeverity("error");
      return;
    }

    setPdfFiles(validPdfs);
  };

  const deleteImage = (e, index) => {
    const updatedImages = [...images];
    const updatedImageFiles = [...imageFiles];

    updatedImages.splice(index, 1);
    updatedImageFiles.splice(index, 1);

    setImages(updatedImages);
    setImageFiles(updatedImageFiles);
  };

  const deletePdf = (index) => {
    const newPdfFiles = [...pdfFiles];
    newPdfFiles.splice(index, 1);
    setPdfFiles(newPdfFiles);
  };

  // const handleFileChange = (event) => {
  //   const files = Array.from(event.target.files);
  //   if (images.length + files.length <= 3) {
  //     const newImages = files.map((file) => ({
  //       previewURL: URL.createObjectURL(file),
  //       file,
  //     }));
  //     setImages([...images, ...newImages]);
  //     setImageFiles([...imageFiles, ...files]);
  //   } else {
  //     alert("You can only upload a maximum of 3 images.");
  //   }
  // };


  const handleFileChange = (event) => {
    // if (!loggedInUserDetails.user_id) {
    //   handleLoginPromptOpen();
    //   setIsApiWorking(false);
    //   return;
    // }
    checkLoginAndProceed(() => {
    const fileList = event.target.files;
    const newImages = [];
    const finalImages = [];

    for (let i = 0; i < Math.min(fileList.length, 3); i++) {
      const file = fileList[i];
      const formData = new FormData();
      finalImages.push(file);
      formData.append(`img_file${i + 1}`, file);
      newImages.push({ formData, previewURL: URL.createObjectURL(file) });
    }

    setImages((prevImages) => [...prevImages, ...newImages]);
    setImageFiles((prevImageFiles) => [...prevImageFiles, ...finalImages]);

    event.target.value = '';  
  });
  };

  const handlePdfDrop = (event) => {
    event.preventDefault();
    const fileList = event.dataTransfer.files;
    handlePdfChange({ target: { files: fileList } });
  };

  const handleDrop = (event) => {
    checkLoginAndProceed(() => {
    event.preventDefault();
    const fileList = event.dataTransfer.files;
    handleFileChange({ target: { files: fileList } });
    });
  };

  useEffect(() => {
    let interval = null;
    if (isApiWorking) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            return prev + 10;
          }
          return prev - 1;
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isApiWorking]);

  const handleSubmit = async () => {
    setIsApiWorking(true);
    trackEvent("Submit", "AIAnswerReview submit");
  
    if (!loggedInUserDetails.user_id) {
      // handleLoginPromptOpen();
      setIsApiWorking(false);
      return;
    }
  
    try {
      if (selectedSection === "pdf") {
        if (pdfFiles.length === 0) {
          setSnackbarMessage("Please upload the PDF.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setIsApiWorking(false);
          return;
        }
  
        const formData = new FormData();
        formData.append("mains_answer_sheet_pdf", pdfFiles[0]);
  
        const response = await axios.post(
          "https://collectorbabu.com/api/mains/mains_save_answer_sheet_pdf",
          formData,
          {
            params: {
              user_id: loggedInUserDetails.user_id,
              subject: selectedSubject,
              use_vision: false,
            },
            headers: {
              accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        );
  
        if (response.status === 200) {
          setPdfInfoMessage(true);
          setSnackbarMessage(
            "Thanks! You will receive your evaluated PDF within 24 hours"
          );
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          setTimeout(() => {
            setPdfFiles([]);
            setSnackbarOpen(false);
            setPdfInfoMessage(false);
            navigate("/dashboard");
          }, 5000);
        } else {
          const errorDetail = response.data?.detail || "Failed to upload the PDF";
          setSnackbarMessage(errorDetail);
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      } else if (selectedSection === "image") {
        if (questionText.trim().length === 0) {
          setOpenSamplePdf(true);       //comment1
          setSnackbarMessage("Please enter a question.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setIsApiWorking(false);
          return;
        }
  
        if (images.length === 0) {
          setSnackbarMessage("Please upload the images.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setIsApiWorking(false);
          return;
        }
  
        if (images.length > 3) {
          setSnackbarMessage("You can upload only 3 images.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setIsApiWorking(false);
          return;
        }
  
        let tempQuestionId = "600000000000000000000000";
        const form = new FormData();
  
        imageFiles.forEach((file, index) => {
          form.append(`img_file${index + 1}`, file);
        });
  
        if (loggedInUserDetails.premium) {
          tempQuestionId = null;
        }
  
        const response = await mainsAnswerReview(
          form,
          loggedInUserDetails.user_id,
          "text",
          tempQuestionId,
          showManualQuestionUpload,
          questionText
        );
  
        if (response.data.status === "already_attempted") {
          setSnackbarMessage("You have already attempted this.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        } else if (response.data.review) {
          navigate("/upsc-mains-answer-evaluation-answer", {
            state: { mainsAnswer: response.data.review },
          });
          setSnackbarMessage("Your images have been successfully uploaded.");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
        } else {
          setSnackbarMessage("Error uploading the images. Please try again.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      }
    } catch (error) {
      console.error("Error submitting:", error);
      setSnackbarMessage(`${error.response?.data?.detail || "Error submitting the file."}`);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setTimeout(() => {
        setPdfFiles([]);
        setSnackbarOpen(false);
        setPdfInfoMessage(false);
      }, 5000);
    } finally {
      setIsApiWorking(false);
    }
  };
  

  // const handleSubmit = async () => {
  //   setIsApiWorking(true);
  //   trackEvent('Submit', 'AIAnswerReview submit');

  //   if (!loggedInUserDetails.user_id) {
  //     handleLoginPromptOpen();
  //     setIsApiWorking(false);
  //     return;
  //   }

  //   try {
  //     if (selectedSection === "pdf") {
  //       if (pdfFiles.length === 0) {
  //         setErrorMessageB2c("Please upload the PDF.");
  //         setQuestionError(true);
  //         setTimeout(() => setQuestionError(false), 5000);
  //         setIsApiWorking(false);
  //         return;
  //       }

  //       const formData = new FormData();
  //       formData.append("mains_answer_sheet_pdf", pdfFiles[0]);

  //       const response = await axios.post(
  //         "https://collectorbabu.com/api/mains/mains_save_answer_sheet_pdf",
  //         formData,
  //         {
  //           params: {
  //             user_id: loggedInUserDetails.user_id,
  //             subject: selectedOption,
  //             use_vision: false,
  //           },
  //           headers: {
  //             accept: "application/json",
  //             "Content-Type": "multipart/form-data",
  //           },
  //         }
  //       );

  //       if (response.status === 200) {
  //         setPdfInfoMessage(true);
  //         setPdfInfoMessageB2c("Thanks! You will receive your evaluated PDF within 24 hours");
  //         setSnackbarMessage("Your PDF has been successfully uploaded.");
  //         setSnackbarSeverity("success");
  //         setTimeout(() => {
  //           setPdfFiles([]);
  //           setPdfInfoMessage(false);
  //           navigate('/dashboard');
  //         }, 5000);
  //       } else {
  //         throw new Error("Failed to upload the PDF");
  //       }
  //     } else if (selectedSection === "image") {
  //       if (questionText.trim().length === 0) {
  //         setErrorMessageB2c("Please enter a question.");
  //         setQuestionError(true);
  //         setTimeout(() => setQuestionError(false), 5000);
  //         setIsApiWorking(false);
  //         return;
  //       }

  //       if (images.length === 0) {
  //         setErrorMessageB2c("Please upload the images.");
  //         setQuestionError(true);
  //         setTimeout(() => setQuestionError(false), 5000);
  //         setIsApiWorking(false);
  //         return;
  //       }

  //       if (images.length > 3) {
  //         setSnackbarMessage("You can upload only 3 images.");
  //         setSnackbarSeverity("error");
  //         setSnackbarOpen(true);
  //         setIsApiWorking(false);
  //         return;
  //       }

  //       let tempQuestionId = "600000000000000000000000";
  //       const form = new FormData();

  //       imageFiles.forEach((file, index) => {
  //         form.append(`img_file${index + 1}`, file);
  //       });

  //       if (loggedInUserDetails.premium) {
  //         tempQuestionId = null;
  //       }

  //       const response = await mainsAnswerReview(
  //         form,
  //         loggedInUserDetails.user_id,
  //         "text",
  //         tempQuestionId,
  //         showManualQuestionUpload,
  //         questionText
  //       );

  //       if (response.data.status === "already_attempted") {
  //         setAlreadyAttempted(true);
  //         setSnackbarMessage("You have already attempted this.");
  //         setSnackbarSeverity("error");
  //       } else if (response.data.review) {
  //         setShowAnswer(true);
  //         setManualAnswer(response.data.review);
  //         setSnackbarMessage("Your images have been successfully uploaded.");
  //         setSnackbarSeverity("success");
  //       } else {
  //         throw new Error("Error uploading the images.");
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error submitting:", error);
  //     setSnackbarMessage("Error submitting the content.");
  //     setSnackbarSeverity("error");
  //   } finally {
  //     setSnackbarOpen(true);
  //     setIsApiWorking(false);
  //   }
  // };

  // const handleMultipleSubmit = async () => {

  //   setIsApiWorking(true);

  //   if (!loggedInUserDetails.user_id) {
  //     handleLoginPromptOpen();
  //     setIsApiWorking(false);
  //     return;
  //   }

  //   if (pdfFiles.length === 0) {
  //     setErrorMessageB2c("Please upload the PDF.");
  //     setQuestionError(true);
  //     setTimeout(() => setQuestionError(false), 5000);
  //     setIsApiWorking(false);
  //     return;
  //   }

  //   try {
  //     for (let i = 0; i < pdfFiles.length; i++) {
  //       const formData = new FormData();
  //       formData.append("mains_answer_sheet_pdf", pdfFiles[i]);

  //       try {
  //         const response = await axios.post(
  //           "https://collectorbabu.com/api/mains/mains_save_answer_sheet_pdf",
  //           formData,
  //           {
  //             params: {
  //               user_id: loggedInUserDetails.user_id,
  //               subject: selectedSubject,
  //               use_vision: false,
  //             },
  //             headers: {
  //               accept: "application/json",
  //               "Content-Type": "multipart/form-data",
  //             },
  //           }
  //         );

  //         if (response.status !== 200) {
  //           console.error("Error uploading PDF:", response.statusText);
  //           throw new Error("Error uploading PDF");
  //         }
  //       } catch (err) {
  //         setSnackbarMessage("PDF already uploaded. Adding Next.");
  //         setSnackbarSeverity("error");
  //         setSnackbarOpen(true);
  //       }
  //     }

  //     setPdfInfoMessage(true);
  //     setPdfInfoMessageB2c(
  //       "Thanks! You will receive your evaluated PDF within 24 hours"
  //     );

  //     setTimeout(() => {
  //       setPdfInfoMessage(false);
  //       setPdfFiles([]);
  //     }, 5000);

  //     setSnackbarMessage("Your PDFs have been successfully uploaded.");
  //     setSnackbarSeverity("success");
  //     setSnackbarOpen(true);
  //   } catch (error) {
  //     console.error("Error submitting PDFs:", error);
  //     setSnackbarMessage("Failed to upload PDFs. Please try again.");
  //     setSnackbarSeverity("error");
  //     setSnackbarOpen(true);
  //   } finally {
  //     setIsApiWorking(false);
  //   }
  // };

  const handleMultipleSubmit = async () => {
    setIsApiWorking(true);
  
    if (!loggedInUserDetails.user_id) {
      handleLoginPromptOpen();
      setIsApiWorking(false);
      return;
    }
  
    if (pdfFiles.length === 0) {
      setErrorMessageB2c("Please upload the PDF.");
      setQuestionError(true);
      setTimeout(() => setQuestionError(false), 5000);
      setIsApiWorking(false);
      return;
    }
  
    let allUploadsSuccessful = true;
  
    for (let i = 0; i < pdfFiles.length; i++) {
      const formData = new FormData();
      formData.append("mains_answer_sheet_pdf", pdfFiles[i]);
  
      const fileName = pdfFiles[i].name.substring(0, 8);
  
      try {
        const response = await axios.post(
          "https://collectorbabu.com/api/mains/mains_save_answer_sheet_pdf",
          formData,
          {
            params: {
              user_id: loggedInUserDetails.user_id,
              subject: selectedSubject,
              use_vision: false,
            },
            headers: {
              accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        );
  
        if (response.status !== 200) {
          throw new Error(response.statusText || "Error uploading PDF");
        }
  
        setSnackbarMessage(`Success: ${fileName} uploaded.`);
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } catch (err) {
        console.error("Error uploading PDF:", err);
  
        setSnackbarMessage(`Error: ${fileName} not uploaded. ${err.response.data.detail}`);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
  
        allUploadsSuccessful = false;
      }
    }
  
    if (allUploadsSuccessful) {
      setPdfInfoMessage(true);
      setPdfInfoMessageB2c("Thanks! You will receive your evaluated PDF within 24 hours");
    } else {
      setSnackbarMessage("Some PDFs Already uploaded.");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
    }
  
    setTimeout(() => {
      setPdfInfoMessage(false);
      setPdfFiles([]);
    }, 5000);
  
    setIsApiWorking(false);
  };
  
  

  const handleLogout = () => {
    setLoggedInUserDetails({});
    navigate("/login");
  };

  const checkLoginAndProceed = (action) => {
    if (!loggedInUserDetails.user_id) {
      // googleLogin();
      navigate('/login');
      return;
    }
    action();
  };
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      //
      // setShowLoginProgressBar(true);
      const userInfo = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
        headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
      });
      //
      let apiData = {
        name: userInfo.data.name,
        email: userInfo.data.email,
        domain: "collectorbabu",
      };

      internalLogin(apiData, userInfo, true);
    },
    onError: (errorResponse) => {
      // setShowLoginProgressBar(false);
    },
  });
  
  const location = useLocation();
  // makes request to login_via_sso and checks if cookie has been set then redirects to /
  const internalLogin = async (data, userInfo, setCookie = false) => {
    try {
      let config = {
        method: "post",
        url: "https://collectorbabu.com/api/user/login_via_sso",
        headers: {
          token: "87957bdf-8fb3-4afc-a5c7-4ad62f785a60",
          "Content-Type": "application/json",
        },
        data: data,
      };

      let response = await axios(config);

      if (setCookie) {
        responseFromGoogleLogin(Object.assign({}, userInfo.data, { 
          user_id: response.data.user_id, 
          premium: response.data.premium 
        }));
        
        setLoggedInUserDetails(Object.assign({}, userInfo.data, { 
          user_id: response.data.user_id, 
          premium: response.data.premium 
        }));
        
        Cookies.set("google_info", JSON.stringify(userInfo.data), { expires: 90 });
        Cookies.set("user_name", userInfo.data.name, { expires: 90 });
        Cookies.set("user_id", response.data.user_id, { expires: 90 });
        Cookies.set("email", userInfo.data.email, { expires: 90 });
        
        navigate(location.state?.from || "/upsc-mains-answer-evaluation");
      } else {
        if (response.data.user_id === data.user_id) {
          responseFromGoogleLogin(
            Object.assign({}, JSON.parse(Cookies.get("google_info")), { 
              user_id: data.user_id, 
              premium: response.data.premium 
            })
          );
          
          setLoggedInUserDetails(
            Object.assign({}, JSON.parse(Cookies.get("google_info")), { 
              user_id: data.user_id, 
              premium: response.data.premium 
            })
          );
          
          navigate(location.state?.from || "/");
        }
      }
    } catch (error) {
      // Error handling
    }
  };

  const handleQuestionChange = (e) => {
    checkLoginAndProceed(() => {
    const inputText = e.target.value;
    setQuestionText(inputText);
    setCharacterCount(inputText.length);
    });
  };

  const handleEvaluate = () => {
    console.log("Evaluating:", { question, images });
  };

  const handleButtonClick = (section) => {
    setSelectedSection(section);
  };

  const handleSubjectChange = (subject) => {
    if (subject === "Optional") {
      setShowOptionalSubjects(true);
      return;
    }
    setSelectedSubject(subject);
  };

  const displayedSubjects = showOptionalSubjects
    ? subjectFilter.filter((sub) => !subjects.includes(sub))
    : subjects;

  const handlePdfDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragOver = (event) => {
    checkLoginAndProceed(() => {
    event.preventDefault();
    });
  };

  const triggerFileInputClick = () => {
    console.log("Input file click");
    const fileInput = document.getElementById("pdfInput");
    console.log(fileInput);
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleRemove = (id) => {
    setImages((prevImages) => prevImages.filter((img) => img.id !== id));
  };

  const handleRotate = (id) => {
    setImages((prevImages) =>
      prevImages.map((img) =>
        img.id === id
          ? { ...img, rotation: ((img.rotation || 0) + 90) % 360 }
          : img
      )
    );
  };

  return (
    <>
    {console.log(loggedInUser)}
    {console.log(loggedInUserDetails)}
      {/* <div className="answer-evaluation-container"> */}
        {/* <Navbar /> */}
        {/* <div className="answer-evaluation-content"> */}
          {/* <Sidebar /> */}
          <div className="answer-evaluation-container-sub">
            <h2 className="answer-e-upload-title ">
              Upload your handwritten answer for{" "}
              <span className="answer-e-highlight">Evaluation</span>
            </h2>
            <section className="pdf-viewer relative">
              {showOptionalSubjects ? (
                <div className="button-container-back">
                  <button className="back-button" onClick={handleBackClick}>
                    Back
                  </button>
                </div>
              ) : (
                <div className="button-container-answer-e">
                  {Object.keys(loggedInUserDetails).length === 0 || loggedInUserDetails?.premium || showImageOption ? 
                  <button
                    className={`button-answer-e ${
                      selectedSection === "image" ? "selected" : ""
                    }`}
                    onClick={() => handleButtonClick("image")}
                  >
                    <CiImageOn />
                    <span className="button-text-answer-e">Basic [Image]</span>
                  </button>
                  : 
                  <motion.button
  className="absolute right-60 -bottom-[860px] md:-right-[400px] md:bottom-0 bg-[#e4ffe0] flex items-center py-2 gap-2 overflow-hidden rounded-lg px-3"
  onClick={() => {
    handleButtonClick("image");
    setShowImageOption(true);
  }}
  onMouseEnter={() => setHovered(true)}
  onMouseLeave={() => setHovered(false)}
  onTouchStart={() => setHovered(true)}
  initial={{ y: 0, width: isMobile.current ? 215 : 215, x: isMobile.current ? 150 : 0 }} // Fixed width
  animate={{
    y: isMobile.current ? 0 : [0, -5, 0], // Only bounce on desktop
    // x: isMobile.current ? 150 : (hovered ? 150 : 0),
  }}
  transition={{
    y: isMobile.current ? {} : { repeat: Infinity, duration: 1.2, ease: "easeInOut" },
    // x: { duration: 0.4, ease: "easeOut" }
  }}
  whileHover={{ y: 0 }} // Stop bouncing on hover
>
  {/* <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className="w-6 h-6 fill-[#2f8965] text-gray-700 min-w-[24px]"
    role="img"
    aria-hidden="true"
  >
    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7-24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
  </motion.svg> */}
  <motion.img
    src={aiImage} // Replace with your image path
    alt="AI Evaluation"
    className="w-6 h-6"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
  />

  {/* Text Always Visible */}
  <motion.span
    className="whitespace-nowrap"
    initial={{ opacity: 1, x: 0 }} // Keep text always visible
    animate={{
      opacity: 1, // Text always visible
      x: 0, // No movement for text
    }}
    transition={{ duration: 0.4, ease: "easeOut" }}
  >
    Try Free AI Evaluation
  </motion.span>
</motion.button>
                  }
                  <button
                    className={`button-answer-e ${loggedInUserDetails.plan !== "zenith" &&
                      loggedInUserDetails.plan !== "zenith_combo" &&
                      loggedInUserDetails.plan !== "zenith_combo_pcs" &&
                      loggedInUserDetails.plan !== "zenith_pcs"  ? 'highlightpdf': ''} ${
                      selectedSection === "pdf" ? "selected" : ""
                    }`}
                    onClick={() => handleButtonClick("pdf")}
                  >
                    <FaRegFilePdf />

                    <span className="button-text-answer-e">Zenith [PDF]</span>
                  </button>
                  {loggedInUserDetails.plan !== "trial" &&
                    loggedInUserDetails.plan !== "peak" &&
                    loggedInUserDetails.plan !== "zenith" &&
                    loggedInUserDetails.plan !== "zenith_combo" &&
                    loggedInUserDetails.plan !== "zenith_pcs" &&
                    loggedInUserDetails.plan !== "zenith_combo_pcs"  && (
                  <Tooltip title="info" arrow>
                  <button 
                    type="button" 
                    onClick={()=>setOpenInfo(true)}
                    aria-label="Information" 
                    className="p-0 rounded focus:outline-none hover:bg-gray-200 top-[20%]"
                  >
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      viewBox="0 0 512 512" 
                      className="w-6 h-6 fill-current text-gray-700"
                      role="img"
                      aria-hidden="true"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
                    </svg>
                  </button>
                  </Tooltip>
                    )}
                  
                </div>
              )}
            </section>
            <div className="answer-evaluation-content-m gap-1" style={{ marginTop: "35px" }}>
              {selectedSection === "image" && (
                <div className="evaluation-container">
                  <h1 className="heading mx-auto">Instant Evaluation</h1>
                  {loggedInUserDetails.plan !== "trial" &&
                    loggedInUserDetails.plan !== "peak" &&
                    loggedInUserDetails.plan !== "zenith" &&
                    loggedInUserDetails.plan !== "zenith_combo" &&
                    loggedInUserDetails.plan !== "zenith_pcs" &&
                    loggedInUserDetails.plan !== "zenith_combo_pcs"  ? (
                      <div className="free-evaluation">
                      <p className="evaluation-count">
                      4 Free evaluations
                    </p>
                    </div>
                    ):<>
                      {}
                    </>}

                  <div className="question-section -mt-2">
                    <label htmlFor="questionInput" className="question-label">
                      Describe your question in plain English
                    </label>
                    <div className="input-container">
                      <textarea
                        id="questionInput"
                        className="question-input"
                        placeholder="Write question here"
                        aria-label="Write your question here"
                        value={questionText}
                        onChange={handleQuestionChange}
                        maxLength={300}
                      />
                      {questionError && (
                        <section className="answer-e-error-alert" role="alert">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="14"
                            viewBox="0 0 15 14"
                            fill="none"
                          >
                            <path
                              d="M7.5 1.75L12.75 11.6667H2.25L7.5 1.75Z"
                              stroke="#FF1304"
                              strokeWidth="0.8"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.5 5.83325V8.16659"
                              stroke="#FF1304"
                              strokeWidth="0.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.5 9.91675V9.92341"
                              stroke="#FF1304"
                              strokeWidth="0.8"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <p className="answer-e-alert-message">
                            {errorMessageB2c}
                          </p>
                        </section>
                      )}

                      <div className="character-count">
                        <span>{characterCount}/300</span>
                        <img
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/5efa65d87aadfc3d2a257741f4d5d68c33d8225ba4c024a93ea7d24d9411da5f?placeholderIfAbsent=true&apiKey=3415081ac2e344e696e4934521b1deb4"
                          alt=""
                          className="count-icon"
                        />
                      </div>
                    </div>
                  </div>

                  <h2 className="upload-title">
                    Upload your hand written answer images
                  </h2>
                  <section
                    className="image-upload-section"
                  >
                    {isApiWorking ? (
                      <div className="answer-e-loader-container">
                        <CircleLoader color="#1F5B43" size={70} />
                        <p className="answer-e-loader-timer">Loading...</p>
                        <p className="answer-e-loader-timer">{timer}s</p>
                      </div>
                    ) : (
                      <div style={{display:"flex", flexDirection:"column"}}>
                        <div
                          className="flex gap-1"
                          id="imageContainer"
                          onDragOver={handleDragOver}
                          onDrop={handleDrop}
                        >
                          {images.length === 0 && (
                            <div  onClick={() =>
                              checkLoginAndProceed(() => {
                                document.getElementById("fileInput").click()
                              })
                              }>
                            <div
                              className="w-100 flex justify-center"
                             
                            >
                              <div className="file-icon-plus-container">
                                <FilePlus color="#1F5B43" size={36} />
                              </div>
                            </div>
                            <p className="text-slate-600">
                            Drag & drop your answer images here or{" "}
                            <span className="pr-1 font-semibold text-emerald-500">
                              choose file
                            </span>
                          </p>
                          </div>
                          )}
                          <input
                            type="file"
                            id="fileInput"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />
                          {images.map((image, index) => (
                            <article key={image.index} className="image-card">
                              <div className="image-content">
                                <div className="image-header">
                                  <span className="image-number">
                                    {index + 1}
                                  </span>
                                  <div className="image-actions">
                                    <button
                                      className="action-button rotate"
                                      aria-label="Rotate image"
                                      onClick={() => handleRotate(index)}
                                    >
                                      <IoRefresh size={28} />
                                    </button>
                                    <button
                                      className="action-button remove"
                                      aria-label="Remove image"
                                      onClick={() => deleteImage(index)}
                                    >
                                      <RxCross2 size={28} />
                                    </button>
                                  </div>
                                </div>
                                <img
                                  src={image.previewURL}
                                  alt={`Uploaded ${image.id}`}
                                  className="uploaded-image"
                                  style={{
                                    transform: `rotate(${
                                      image.rotation || 0
                                    }deg)`,
                                  }}
                                />
                                <p className="image-caption">
                                  {/* {image.file.name.substring(8)} */}
                                </p>
                              </div>
                            </article>
                          ))}
                        </div>
                        {images.length > 0 && images.length < 3 && (
                          <div
                            className="flex w-full cursor-pointer items-center justify-center"
                            onClick={() =>
                              document.getElementById("fileInput").click()
                            }
                          >
                            <div className="font-semibold text-emerald-500">
                              {images.length === 1
                                ? "+ Click to add 2ⁿᵈ Page"
                                : "+ Click to add 3ʳᵈ Page"}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </section>

                  <div className="upload-limit">
                    <span>Max 3 Images</span>
                    <img
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/5efa65d87aadfc3d2a257741f4d5d68c33d8225ba4c024a93ea7d24d9411da5f?placeholderIfAbsent=true&apiKey=3415081ac2e344e696e4934521b1deb4"
                      alt=""
                      className="limit-icon"
                    />
                  </div>

                  <button className="evaluate-button" onClick={() =>
                              checkLoginAndProceed(() => {
                                {handleSubmit()}
                              })}>
                    Evaluate
                  </button>
                </div>
              )}

              {selectedSection === "pdf" && (
                <div className="evaluation-container">
                  <h2 className="heading">Guaranteed 24-Hour Evaluation</h2>
                  {loggedInUserDetails.plan !== "trial" &&
                    loggedInUserDetails.plan !== "peak" &&
                    loggedInUserDetails.plan !== "zenith" &&
                    loggedInUserDetails.plan !== "zenith_combo" &&
                    loggedInUserDetails.plan !== "zenith_pcs" &&
                    loggedInUserDetails.plan !== "zenith_combo_pcs"  && (
                      <div className="try-zenith-container">
                        <button
                          onClick={() => navigate("/pricing")}
                          className="try-zenith-button"
                        >
                          Try Zenith @19/-
                        </button>
                      </div>
                    )}
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "14px",
                      }}
                    >
                      <div className="select-subject">Select subject</div>
                      {loggedInUserDetails.plan !== "trial" &&
                        loggedInUserDetails.plan !== "peak" &&
                        loggedInUserDetails.plan !== "zenith" &&
                        loggedInUserDetails.plan !== "zenith_combo" &&
                        loggedInUserDetails.plan !== "zenith_pcs" &&
                        loggedInUserDetails.plan !== "zenith_combo_pcs"  && (
                          <button className="example">
                            <FaWandMagicSparkles />
                            <div className="sample-evaluation">
                              <a
                                href="https://storage.googleapis.com/evaluated-answer-sheets/evaluated/65227f54b4a9f675b0d6afc2_a8ea5e5cd6fad303e3347344301d61f9f72c41e2200f1f3e52d7ec7bcd5cea99.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Sample evaluation
                              </a>
                            </div>
                          </button>
                        )}
                    </div>
                    <div className="subjects-2">
                      <div className="subjects-3">
                        {displayedSubjects.map((subject) => (
                          <button
                            key={subject}
                            className={`subject-button ${
                              selectedSubject === subject.value ? "active" : ""
                            }`}
                            onClick={() => handleSubjectChange(subject.value)}
                          >
                            {subject.label}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="image-upload">
                    <div className="answer">
                      <div className="upload-your-hand-written-answer">
                        Upload your hand written answer
                      </div>
                      {questionError && (
                        <section className="answer-e-error-alert" role="alert">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="14"
                            viewBox="0 0 15 14"
                            fill="none"
                          >
                            <path
                              d="M7.5 1.75L12.75 11.6667H2.25L7.5 1.75Z"
                              stroke="#FF1304"
                              strokeWidth="0.8"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.5 5.83325V8.16659"
                              stroke="#FF1304"
                              strokeWidth="0.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.5 9.91675V9.92341"
                              stroke="#FF1304"
                              strokeWidth="0.8"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <p className="answer-e-alert-message">
                            {errorMessageB2c}
                          </p>
                        </section>
                      )}
                      {loggedInUserDetails.plan !== "trial" &&
                        loggedInUserDetails.plan !== "peak" &&
                        loggedInUserDetails.plan !== "zenith" &&
                        loggedInUserDetails.plan !== "zenith_combo" &&
                        loggedInUserDetails.plan !== "zenith_pcs" &&
                        loggedInUserDetails.plan !== "zenith_combo_pcs"  && (
                          <section onClick={() => navigate("/pricing")} style = {{cursor:"pointer"}} className="unlock-feature">
                            <div className="unlock-content">
                              <div className="lock-icon">
                                <span className="lock-image">
                                  <GoLock size={31} color="red" />
                                </span>
                              </div>
                              <p className="unlock-text">
                                Unlock with Trial or Zenith Plans
                              </p>
                            </div>
                          </section>
                        )}

                      {(loggedInUserDetails.plan === "trial" ||
                        loggedInUserDetails.plan === "peak" ||
                        loggedInUserDetails.plan === "zenith" ||
                        loggedInUserDetails.plan === "zenith_combo" ||
                        loggedInUserDetails.plan === "zenith_pcs" ||
                        loggedInUserDetails.plan === "zenith_combo_pcs" ) && (
                        <div
                          className="answer-2"
                          onDragOver={handlePdfDragOver}
                          onDrop={handlePdfDrop}
                        >
                          {!pdfFiles.length && (
                            <div className="description" onClick={() =>
                                  document.getElementById("pdfInput").click()
                                }>
                              <div className="file-icon-plus-container">
                                <FilePlus size={36} />
                              </div>
                              <div
                                className="drag-drop-your-answer-pdf-here-or-choose-file"
                                
                              >
                                Drag & drop your answer PDF here or{" "}
                                <label className="choose-file">
                                  choose file
                                </label>
                              </div>
                            </div>
                          )}
                          <input
                                    type="file"
                                    onChange={handlePdfFileChange}
                                    id="pdfInput"
                                    multiple={
                                      loggedInUserDetails.user_id ===
                                        "659662fb004103e6fd40aac4" ||
                                      loggedInUserDetails.user_id ===
                                        "66864f08ef7da0f98ef5ed22" ||
                                      loggedInUserDetails.user_id ===
                                        "64ba4fdf3572eafed73db968" ||
                                      loggedInUserDetails.user_id ===
                                        "648c663016aa3c84255ed879" ||
                                      loggedInUserDetails.user_id ===
                                        "6731e0fa074189474083557e"
                                    }
                                    accept=".pdf"
                                    style={{ display: "none" }}
                                  />
                          <div
                            className={`pdf-preview ${
                              pdfFiles.length > 0 ? "pdf-preview-active" : ""
                            }`}
                            style={{
                              height: pdfFiles.length > 4 ? "170px" : "auto",
                              overflow: pdfFiles.length > 4 ? "auto" : "hidden",
                            }}
                          >
                            {isApiWorking && (
                              <div className="answer-e-loader-container">
                              <CircleLoader color="#1F5B43" size={70} />
                              </div>
                            )}

                            {pdfFiles.map((file, index) => (
                              <div
                                className="answer-e-file-container"
                                key={index}
                              >
                              
                                <div className="answer-e-icon-with-text">
                                  <FilePlus size={28} />
                                  <div className="answer-e-text">
                                    {file.name.length > 20
                                      ? `${file.name.slice(0, 20)}...`
                                      : file.name}
                                  </div>
                                </div>
                                <button
                                  onClick={() => deletePdf(index)}
                                  className="answer-e-action-button"
                                  aria-label="More options"
                                >
                                  <RxCross2 size={28} />
                                </button>
                              </div>
                            ))}
                            {/* {pdfFiles.length > 0 && (
                              <button
                                className="button-evaluate"
                                onClick={() =>
                                  document.getElementById("pdfInput").click()
                                }
                              >
                                Upload More Files
                              </button>
                            )} */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {loggedInUserDetails.user_id === "659662fb004103e6fd40aac4" ||
                  loggedInUserDetails.user_id === "66864f08ef7da0f98ef5ed22" ||
                  loggedInUserDetails.user_id === "64ba4fdf3572eafed73db968" ||
                  loggedInUserDetails.user_id === "648c663016aa3c84255ed879" ||
                  loggedInUserDetails.user_id === "6731e0fa074189474083557e" ? (
                    <button
                      className="button-evaluate"
                      onClick={handleMultipleSubmit}
                    >
                      Submit Multiple Files
                    </button>
                  ) : (
                    <button className="button-evaluate" onClick={handleSubmit}>
                      Submit
                    </button>
                  )}
                </div>
              )}
              {showAnswer && (
                <MainsAnswer
                  mainsAnswer={manualAnswer}
                  showManualQuestionUpload={showManualQuestionUpload}
                  modelAnswer={modelAnswer}
                />
              )}
            </div>

            {loggedInUserDetails.plan !== "trial" &&
                        loggedInUserDetails.plan !== "peak" &&
                        loggedInUserDetails.plan !== "zenith" &&
                        loggedInUserDetails.plan !== "zenith_combo" &&
                        loggedInUserDetails.plan !== "zenith_pcs" &&
                        loggedInUserDetails.plan !== "zenith_combo_pcs"  && (
                          <div className="mobile-review-container-responsive flex flex-col items-start p-20 max-md:px-5">
      <div className="review-container-b2c-tr self-start mt-4 ml-5 text-xl font-extrabold tracking-wide leading-6 text-gray-900 uppercase max-md:max-w-full">
        Trusted by UPSC Toppers
      </div>
      <div className="review-container-b2c-tr mt-2 ml-5 text-4xl font-semibold tracking-wider leading-10 text-gray-900 w-[600px] max-md:max-w-full">
        The Go-To Platform for Serious Aspirants: CollectorBabu
      </div>
      <div className="self-center mt-16 w-full max-w-[1240px] max-md:mt-10 max-md:max-w-full">
<div className="special-image-container">
<div className="special-image-wrapper">
  <img src="/images/testimonials/testimonial_7.jpg" alt="Special" className="special-uploaded-image" loading="lazy"/>
  <h1 className="special-serial-number">Rishabh Dev, AIR 167 CSE ‘23</h1>
</div>
<div className="special-image-wrapper">
  <img src="/images/testimonials/testimonial_8.jpg" alt="Special" className="special-uploaded-image-two" loading="lazy"/>
  <h1 className="special-serial-number">Anubhav, AIR 309 CSE ‘23</h1>
</div>
</div>
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
              <div className="flex flex-col px-6 pb-6 bg-white rounded-lg border border-solid max-md:px-5 max-md:max-w-full">
                <div className="review-container-b2c-tr text-xl leading-8 text-gray-700 max-md:max-w-full">
                I wrote CSE-Mains 2023 and undoubtedly I can say that
                  Collector Babu helped me to fine tune my answer writing skills
                  with its Al Tool. I had always been apprehensive about the
                  level of precision that Al based tools can achieve especially
                  with handwritten answer scripts. However, the quality of
                  feedback, the scope of improvements clearly proved me wrong
                  and now I believe that CB's Al Tool will be a market disruptor
                  in this mundane Answer evaluation field where aspirants shell
                  out money and still are quite unsure whether they are guided
                  in the right direction.Hopefully, I get the the opportunity
                  to use your Al Mock interview Tool as well.
                </div>
                <div className="flex gap-3 mt-8 max-md:flex-wrap">
                <InitialsImage name="Nilesh" />
                  <div className="flex flex-col flex-1 max-md:max-w-full">
                    <div className="review-container-b2c-tr text-lg font-semibold text-gray-700 uppercase max-md:max-w-full">
                      Nilesh
                    </div>
                    <div className="review-container-b2c-tr text-sm font-semibold text-gray-700 uppercase max-md:max-w-full">
                    APPEARED FOR INTERVIEW CSE ‘23
                    </div>
                  </div>
                </div>
              </div>
              {/* appeared for interview */}
              <div className="flex flex-col p-6 mt-6 bg-white rounded-lg border border-solid max-md:px-5 max-md:max-w-full">
                <div className="review-container-b2c-tr text-xl leading-8 text-gray-700 max-md:max-w-full">
                I appeared in CSE Mains 2023 & CB's Al review for essay was
                  quite helpful during my preparation. Its feedback comments
                  were precisely on point, on which I could improve. This was
                  way better than the answer evaluation at institutes, that
                  comes at a huge cost yet with mediocre quality.I am immensely
                  grateful to the team for this innovative initiative. I hope CB
                  proves to be a game changer for me in CSE 2023 & guides
                  upcoming aspirants as well.
                </div>
                <div className="flex gap-3 mt-8 max-md:flex-wrap">
                <InitialsImage name="Chhaya Chaudhary" />
                  <div className="flex flex-col flex-1 max-md:max-w-full">
                    <div className="review-container-b2c-tr text-lg font-semibold text-gray-700 uppercase max-md:max-w-full">
                    Chhaya Chaudhary
                    </div>
                    <div className="review-container-b2c-tr text-sm font-semibold text-gray-700 uppercase max-md:max-w-full">
                    APPEARED FOR MAINS ‘23
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col self-stretch my-auto max-md:mt-10 max-md:max-w-full">
              <div className="flex flex-col p-6 bg-white rounded-lg border border-solid max-md:px-5 max-md:max-w-full">
                <div className="review-container-b2c-tr text-xl leading-8 text-gray-700 max-md:max-w-full">
                The AI based feedback for a few answers and essay was very
                  good. I got to know how to make relevant introduction to
                  demand of question and not a general into that I used to do in
                  essay, it helped me in my week area that is conclusion and
                  also how to substantiate the ideas better and not just mention
                  for the sake of it.And not to mention the speed of checking
                  which is a sin qua non for last few days before exam Overall
                  it was a good experience to use Al for the first time .Thanks
                  for cooperation and support Both technical team and Harsh
                  helped me analyse the essay output in-person as well.
                </div>
                <div className="flex gap-3 mt-8 max-md:flex-wrap">
                <InitialsImage name="Manpreet Sharma" />
                  <div className="flex flex-col flex-1 max-md:max-w-full">
                    <div className="review-container-b2c-tr text-lg font-semibold text-gray-700 uppercase max-md:max-w-full">
                    Manpreet Sharma
                    </div>
                    <div className="review-container-b2c-tr text-sm font-semibold text-gray-700 uppercase max-md:max-w-full">
                    Appeared for Interview CSE ‘23
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col p-6 mt-6 bg-white rounded-lg border  border-solid max-md:px-5 max-md:max-w-full">
                <div className="review-container-b2c-tr text-xl leading-8 text-gray-700 max-md:max-w-full">
                  I found the CollectorBabu's answer evaluating tool useful
                  while preparing for this year's mains exam. It transformed the
                  way I approached answer writing.The tool provided me with
                  instant detailed feedback on my answers highlighting areas
                  where I needed improvement. Not only did it save me precious
                  time that would have been spent waiting for manual evaluation,
                  but it also gave me the confidence to tackle the exam with a
                  well-honed skillset.Thanks to the team for putting so much
                  effort to bring this wonderful initiative.
                </div>
                <div className="flex gap-3 mt-8 max-md:flex-wrap">
                <InitialsImage name="Darshan" />
                  <div className="flex flex-col flex-1 max-md:max-w-full">
                    <div className="review-container-b2c-tr text-lg font-semibold text-gray-700 uppercase max-md:max-w-full">
                      Darshan
                    </div>
                    <div className="review-container-b2c-tr text-sm font-semibold text-gray-700 uppercase max-md:max-w-full">
                    Appeared for Mains ‘23
                    </div>
                  </div>
                </div>
              </div>
               {/* appeared for interview */}
              {/* <div className="flex flex-col p-6 mt-6 bg-white rounded-lg border border border-solid max-md:px-5 max-md:max-w-full">
                <div className="review-container-b2c-tr text-xl leading-8 text-gray-700 max-md:max-w-full">
                Highly recommended. Helped me write better answers.
                </div>
                <div className="flex gap-3 mt-8 max-md:flex-wrap">
                <InitialsImage name="Vaqilbabu" />
                  <div className="flex flex-col flex-1 max-md:max-w-full">
                    <div className="review-container-b2c-tr text-lg font-semibold text-gray-700 uppercase max-md:max-w-full">
                      Vaqilbabu
                    </div>
                    <div className="review-container-b2c-tr text-sm font-semibold text-gray-700 uppercase max-md:max-w-full">
                    Appeared for Mains ‘23
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="z-10 shrink-0 self-center mt-0 max-w-full h-[90px] w-[1240px] max-md:mt-0" />
    </div>
              )}
          </div>
        {/* </div> */}
      {/* </div> */}
      <Dialog
        open={openInfo}
        onClose={handleCloseInfo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
        <DialogContent>
          <span id="alert-dialog-description" className="text-gray-900">
            Basic Model: Instant AI-only evaluation for single answers.<br/><br/>
            Zenith Model: More comprehensive and advanced evaluation with detailed PDF remarks and human review in 24 hours.
          </span>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button onClick={handleCloseInfo} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <LoginPrompt
        openLoginPrompt={openLoginPrompt}
        handleLoginPromptClose={handleLoginPromptClose}
      />
             <PhonePop mobavail={mobAvail}/>
    </>
  );
};

export default AnswerEvaluationContainer;

import React from 'react'
import { Typography } from '@mui/material';
import { Chart } from "react-google-charts";
import { PieChart, Pie, Cell, ResponsiveContainer, Sector } from 'recharts';

function Results({submissionStats}) {
  let { correctAnswers, wrongAnswers, repeatedCorrectAnswers, repeatedWrongAnswers, unAttemptedAnswers } = submissionStats;
    const data = [
        ["Response", "Percentage"],
        ["Correct Answers", correctAnswers],
        ["Wrong Answers", wrongAnswers],
        ["Not Attempted", unAttemptedAnswers],
      ];

      const total = correctAnswers + wrongAnswers + unAttemptedAnswers;
      const data2 = [
        { 
          name: 'Wrong Answers', 
          value: (wrongAnswers / total) * 100,
          color: '#FF4B4B'
        },
        { 
          name: 'Correct Answers', 
          value: (correctAnswers / total) * 100,
          color: '#4CAF50'
        },
        { 
          name: 'Not Attempted', 
          value: (unAttemptedAnswers / total) * 100,
          color: '#FFC107'
        }
      ];
      const wrongAnswerPercentage = data2.find(item => item.name === 'Wrong Answers')?.value || 0;
      const correctAnswerPercentage = data2.find(item => item.name === 'Correct Answers')?.value || 0;
      const NotattemptAnswerPercentage = data2.find(item => item.name === 'Not Attempted')?.value || 0;
      const renderCustomizedLabel = (props) => {
        const { cx, cy, midAngle, innerRadius, outerRadius, value, index } = props;
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
        return (
          <text 
            x={x} 
            y={y} 
            fill="white" 
            textAnchor="middle" 
            dominantBaseline="central"
            className="text-sm font-semibold"
          >
            {`${value.toFixed(2)}%`}
          </text>
        );
      };
    
      
      const options = {
        title: "",
        pieHole: 0.4,
        is3D: true,
      };

  return (
    <div className='flex flex-col justify-center items-center w-full px-4'>
        {/* <h1 className='font-bold text-center text-gray-600'>Results</h1> */}
            {/* <div className='circularProgressBar md:w-[50%]' >
                <Chart
                    chartType="PieChart"
                    width="100%"
                    height="400px"
                    data={data}
                    options={options}
                />
            </div> */}
            <div class="h-fit justify-center items-center gap-[180px] inline-flex flex-wrap">
            <div class="w-[298px] flex-col justify-start items-start gap-[26px] inline-flex">
                <div class="self-stretch text-gray-700 text-xl font-semibold font-['Manrope'] leading-snug">Overall Performance</div>
                <div class="self-stretch h-[106px] flex-col justify-start items-start gap-5 flex">
                    <div class="justify-start items-center gap-6 inline-flex">
                        <div class="h-[22px] justify-start items-center gap-3 flex">
                            <div class="w-5 h-5 bg-[radial-gradient(circle_at_center,_white_0%,_#ff5959_60%)] rounded-[60px]"></div>
                            <div class="text-gray-800 text-lg font-normal font-['Manrope'] leading-snug">Wrong Answer</div>
                        </div>
                        <div class="text-gray-800 text-lg font-normal font-['Manrope'] leading-snug">{wrongAnswers} ({wrongAnswerPercentage.toFixed(2)}%)</div>
                    </div>
                    <div class=" justify-start items-center gap-6 inline-flex">
                        <div class="justify-start items-center gap-3 flex">
                            <div class="w-5 h-5 bg-[radial-gradient(circle_at_center,_white_0%,_#4dec71_60%)] rounded-[60px]"></div>
                            <div class="text-gray-800 text-lg font-normal font-['Manrope'] leading-snug">Correct Answer</div>
                        </div>
                        <div class="text-gray-800 text-lg font-normal font-['Manrope'] leading-snug">{correctAnswers} ({correctAnswerPercentage.toFixed(2)}%)</div>
                    </div>
                    <div class="justify-start items-center gap-6 inline-flex">
                        <div class="h-[22px] justify-start items-center gap-3 flex">
                            <div class="w-5 h-5 bg-[radial-gradient(circle_at_center,_white_0%,_#ffd338_60%)] rounded-[60px]"></div>
                            <div class="text-gray-800 text-lg font-normal font-['Manrope'] leading-snug">Not Attempted</div>
                        </div>
                        <div class="text-gray-800 text-lg font-normal font-['Manrope'] leading-snug">{unAttemptedAnswers} ({NotattemptAnswerPercentage.toFixed(2)}%)</div>
                    </div>
                </div>
            </div>
                <div class="w-[360px] h-[360px] bg-white rounded-full shadow-[0px_4px_50px_0px_rgba(0,0,0,0.08)] border border-black/10 flex justify-center items-center">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={data2}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      innerRadius={120}
                      outerRadius={150}
                      startAngle={90}
                      endAngle={-270}
                      paddingAngle={5}
                      cornerRadius={20}
                    >
                      {data2.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    </div>
  )
}

export default Results
import { Interweave } from "interweave";
import { styled } from "@mui/material/styles";
import { Card, CardContent, Typography, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Collapse, CardActions } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
// import Option from "./Option";
import Option from "../PYQFastTrack/Option";
import { memo, useState } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { LightBulbIcon } from "@heroicons/react/24/outline";
// incase more options than 4, defensive handling
const choicesList = ["A", "B", "C", "D", "E", "F", "G"];

const StyledListItemButton = styled(ListItem)(({ listtitle }) => ({
  "&::before": {
    content: `"${listtitle}"`,
    position: "absolute",
    top: "-18px",
    left: "5px",
    padding: "",
    textAlign: "center",
  },
}));


const PrelimsMcq = ({ data, handleOptionSelect, cardIndex, selectedQuestion, isWrongMarkedQuestion, examSubmitted, defaultExpand }) => {
  const { question, options, correctChoiceIndex, selectedOption, detailed_solution, yearAsked, parentExam, hack} = data;
  const [expanded, setExpanded] = useState(defaultExpand);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return (
      <div className="w-[100%]  flex justify-between items-center">
      <Typography variant="h5" className={`${!expanded ? 'text-gray-700': 'text-blue-700'}  text-xl font-bold font-['Manrope']`}>
        Detailed Solution
      </Typography>
      <IconButton 
        sx={{ color: expanded ? 'blue' : 'grey' }} 
        {...other} 
      />
      </div>
    );
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginRight: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  return (
    <>
      {/* <CardContent> */}
      <div className="flex flex-col min-w-full max-h-fit items-stretch ml-0 mb-0 mt-[-50px]">
        <div className="flex flex-col">
          <div className="flex w-full justify-end">
        {/* <Typography 
          className="h-7 w-fit px-2 py-1 bg-indigo-50 rounded-[32px] text-indigo-500 text-sm font-normal font-['Inter'] leading-tight inline-flex items-center text-end justify-end"
          gutterBottom
        >
          {parentExam} {yearAsked}
        </Typography> */}
        </div>
        {/* <Typography variant="h6" component="div" sx={{ textAlign: "start"}}>
          <Interweave content={question}></Interweave>
        </Typography> */}
        <h3 className="text-start  text-gray-700 text-base font-semibold font-['Manrope'] leading-[30px]">
          <Interweave content={question}></Interweave>
        </h3>
        </div>
        <div className=" mt-3">
        <RadioGroup
          aria-label="quiz-options"
          name="quiz-options"
          value={selectedOption}
          onChange={(event) => {
            const selectedIndex = parseInt(event.target.value);
            const newSelectedOption = selectedIndex === selectedOption ? -1 : selectedIndex;
            
            const eventData = {
              currentTarget: {
                dataset: {
                  cardindex: cardIndex.toString(),
                  optionindex: newSelectedOption.toString(),
                  iswrongmarkedquestion: isWrongMarkedQuestion.toString()
                }
              }
            };
            handleOptionSelect(eventData);
          }}
        >
          <List>
            {options.map((option, index) => {
              let borderColor ="lightgrey";
              let outlineText = "";
              let fontsize = window.innerWidth < 768 ? '1rem' : '1rem'
              let lineheight = window.innerWidth < 768 ? '1rem' : '1rem';
              let backgroundColorAfterSubmit = "";
              if (examSubmitted) {
                if (index === correctChoiceIndex) {
                  outlineText = "Correct Answer";
                  borderColor = "#34D399";
                  fontsize = window.innerWidth < 768 ? '1rem' : '1rem';
                  lineheight = window.innerWidth < 768 ? '1rem' : '1rem';
                  backgroundColorAfterSubmit = "bg-green-200";
                } else if (index === selectedOption && correctChoiceIndex !== selectedOption) {
                  outlineText = "You Selected";
                  borderColor = "#f64f59";
                  fontsize = window.innerWidth < 768 ? '1rem' : '1rem';
                  lineheight = window.innerWidth < 768 ? '1rem' : '1rem';
                  backgroundColorAfterSubmit = "bg-red-200";
                } else if (index === correctChoiceIndex && selectedOption === -1) {
                  outlineText = "Not Attempted Correct Answer";
                  fontsize = window.innerWidth < 768 ? '1rem' : '1rem';
                  lineheight = window.innerWidth < 768 ? '1rem' : '1rem';
                  backgroundColorAfterSubmit = "green";
                }
              } else if (index === selectedOption) {
                backgroundColorAfterSubmit = "lightblue";
              }

              return (
                <ListItem key={index} 
                  onClick={() => {
                    const newSelectedOption = selectedOption === index ? -1 : index;     
                    const eventData = {
                      currentTarget: {
                        dataset: {
                          cardindex: cardIndex.toString(),
                          optionindex: newSelectedOption.toString(),
                          iswrongmarkedquestion: isWrongMarkedQuestion.toString()
                        }
                      }
                    };
                    handleOptionSelect(eventData);
                  }}
                  sx={{
                    ...((!examSubmitted) 
                      ? { border: '2px solid', borderColor: borderColor }
                      : { borderBottom: '2px solid', borderColor: borderColor }
                    ),
                    marginY: 1,
                    borderRadius: "10px",
                    width: {sm: '100%', md:'60%'},
                    fontSize: fontsize,
                    lineHeight: lineheight,
                    overflow: 'visible',
                    cursor: 'pointer',
                    padding: {xs: '2px 0px', sm: '8px 16px'},
                    minHeight: {xs: 'auto', sm: '48px'},
                    // You can also adjust vertical alignment for better appearance
                    // alignItems: 'flex-start',
                  }}
                >
                  <Radio
                    checked={selectedOption === index}
                    // onChange={() => {}}
                    // onClick={(e) => {
                    //   // e.stopPropagation();
                    // }}
                    sx={{ marginRight: 1, pointerEvents: 'none' }}
                  />
                  <div style={{ width: '100%' }}>
                    <Interweave content={option} />
                  </div>
                  <span style={{ 
                    position: 'absolute', 
                    // top: {xs: '40px', md:'18px'}, 
                    top: window.innerWidth < 768 ? '33px' : '38px',
                    // lineHeight: '.5rem',
                    right: window.innerWidth < 768 ? '5px': '4px', 
                    backgroundColor: 'white', 
                    paddingInline: '5px', 
                    borderRadius: '5px', 
                    fontSize: window.innerWidth < 768 ? '15px' : '17px', 
                    color: 'gray' 
                  }}>
                    {outlineText}
                  </span>
              </ListItem>
            );
          })}
          </List>
        </RadioGroup>
        </div>
      </div>
      {/* </CardContent> */}
      {examSubmitted ? (
        <>
        {hack && (
          <div className="flex flex-col px-3 py-2 border border-gray-500 gap-3 w-full my-2">
            <h3 className="flex gap-2 min-w-fit font-bold text-lg border-b">Hack <LightBulbIcon className="w-6 h-6" /> </h3>
            <Typography paragraph>
              <Interweave content={hack} className="font-semibold"></Interweave>
            </Typography>
          </div>
        )}
          <Card variant="outlined" sx={{ mb: 1 }}>
            <CardActions disableSpacing  onClick={handleExpandClick}>
              <ExpandMore expand={expanded}  aria-expanded={expanded} aria-label="show more">
                <ExpandMoreIcon />
              </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent sx={{backgroundColor:"white"}}>
                <Typography paragraph>
                  <Interweave content={detailed_solution}></Interweave>
                </Typography>
              </CardContent>
            </Collapse>
          </Card>
        </>
      ) : null}
    </>
  );
};

export default PrelimsMcq;